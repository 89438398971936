//Page statique
.page-description {
    padding: 15px;
}

.taxonomy-term-description {
    @include xssm {
        padding: 10px;
    }
}

.field-name-field-background-image {
    img {
        height: auto;
        width: 100%;
    }
}

.parallax-window {
    background: transparent;
    min-height: 200px;
}

.page-payment-cardrenew,
.node-type-page {
    article {
        background: color(light);
        margin: 30px;
        padding: 30px;

        @include lg {
            margin: 30px auto;
            max-width: $html-max-width;
        }
    }
}

.page-payment-cardrenew {
    article {
      h2 {
        font-weight: font-weight(bold);
      }

      p {
        color: color(gray, darker);
      }
    }

    .form-group {
        display: block;
        margin-bottom: space(.25);

        label {
            color: color(gray, darker);
            margin-bottom: 0;
        }
    }

    .form-control {
        display: block;
    }
}

//page static (cgv, cgu)
.node-page {
    color: color(dark);
    font-size: 16px;

    p, ul {
        margin-bottom: 15px;
        max-width: 800px;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }
}
