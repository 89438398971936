div.messages {
    margin: auto space();
    max-width: 1270px;
    overflow: hidden;
    padding: space() space() space() space(5);
    width: auto;

    &:empty {
        display: none;
    }

    @include lg {
        margin: auto;
        width: calc(100% - #{space(2)});
    }

    &.status {
        background: color(success);
        border: 0;
        color: color(light);
        margin-top: space();
        position: relative;

        &::before {
            content: $icon-notif-info;
            font-family: main;
            font-size: 30px;
            left: space(2);
            margin-right: space();
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        h1 {
            color: color(light);
            padding-bottom: 0;
        }
    }

    p {
        margin: 0;
    }

    h1 {
        border: none;
        font-size: 24px;
        margin-bottom: 15px;
        padding-bottom: 0;
    }

    .krumo-root {
        color: color(dark);

        ul {
            margin-left: 0;
        }
    }
}
