//
// Menu
//

// Base styles
//

r-menu {
    font-size: font-size(base);
}

r-submenu {
    .r-menu--link {
        font-weight: normal;
    }
}

.r-menu--link {
    font-weight: bold;
    line-height: 1;
    padding: 6px space(.5);

    &:hover {
        background: color(gray, lighter);
        text-decoration: none;
    }
}

r-footer {
    r-menu {
        font-size: font-size(base);
        text-align: right;
    }

    r-menu--item {
        display: inline-block;
    }
}

.r--menu-m-lesoir,
.r--menu-webform-lesoir {
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;

    @include mdlg {
        font-size: 1.2rem;
        margin-left: 20px;
        overflow-x: auto;
    }

    .node-type-webform & {
        display: none;
    }
}
.r--menu-webform-lesoir {
    .node-type-webform & {
        display: flex;
    }
}
