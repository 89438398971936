#views-exposed-form-recherche-page {

  .r--search {
    &::before {
      left: space();
    }
  }

  label {
    color: color(primary);
    font-family: $font-family-title;
  }

  #edit-search,
  #edit-search:focus {
    background-color: color(light);
    border-radius: 2rem;
    padding: space(.75) space(.5) space(.75) space(2.5);
  }
}

.view-filters {
  margin-top: space(2);
}

.faq-processed {
  font-weight: 300;
}