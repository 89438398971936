@font-face {
    font-family: 'montserrat';
    font-style: normal;
    font-weight: bold;
    src:  url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'montserrat';
    font-style: normal;
    font-weight: 300;
    src:  url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/montserrat/montserrat-light-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'grenzecho';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/grenzecho/Grenzecho-Serif-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'grenzecho';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/grenzecho/Grenzecho-Serif-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'economica';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/economica/Economica-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'shadows';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/shadows/ShadowsIntoLightTwo-Regular.woff2') format('woff2'),
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/shadows/ShadowsIntoLightTwo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
