// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------

// Colors
// -----------------------------------------------------------------------------

$colors:                             (
    // Grayscale
    //
    gray:                            (
        lighter:                     #fafaff,
        light:                       #d1d4d8,
        base:                        #797979,
        dark:                        #575757,
        darker:                      #242424,
    ),

    // Branding
    //
    primary:                         #043556,
    secondary:                       #fdc800,
    menu:                            #043556,
    submenu:                         #043556,
    button:                          #fdc800,
    red:                             #840303,
    green:                           #099,
    blue-gray:                       #B2C6E2,
    lightblue:                       #f8fbff,
    blue-light:                      #EFF6FE,
    pink:                            #f36991,

    // Social
    //
    facebook:                               #3b5998,
    twitter:                                #4099ff,
    vimeo:                                  #1ab7ea,
    dailymotion:                            #0066a6,
    linkedin:                               #0274b3,
    youtube:                                #cc181e,
    deezer:                                 #1990db,
    pinterest:                              #cb2027,
    google:                          #4689F2,

    // UI Colors
    //
    success:                         #09b081,
    warning:                         #d04343,s
    info:                            #005dc4,
    tooltip:                         #003978,
);

$yellow-degrade: linear-gradient(250deg, rgba(253,200,0,1) 36%, rgba(253,185,0,1) 100%);
$blue-degrade: linear-gradient(180deg, rgba(0,56,119,1) 36%, rgba(9,32,86,1) 100%);
$white : #ffffff;
// Font
// Font, line-height, and color for body text, headings, and more.
// -----------------------------------------------------------------------------

// Base font family
$font-family-base:                   'montserrat', 'Helvetica Neue', arial, sans-serif;
// Title font family
$font-family-title:                  'grenzecho', Georgia, arial, sans-serif;
// Alternative font family
$font-family-alt:                    'montserrat', 'Helvetica Neue', arial, sans-serif;
//Font family handwriting
$font-family-alt-cursive:             'shadows', cursive, sans-serif;

// Font size
$font-size:                          (
    base:                            1rem,
    h1:                              2.5rem,
    h2:                              2.1rem,
    h3:                              1.7rem,
    h4:                              1.3rem,
    h5:                              1.12rem,
    h6:                              1rem,
    small:                           .8rem,
    alt:                             1rem,
    nav:                             1.3rem,
    button:                          1.12rem,
    extrasmall:                      .6rem
);

// Line height
$line-height:                        (
    base:                            1.3,
    h1:                              1,
    h2:                              1.3,
    h3:                              1.3,
    h4:                              1.3,
    h5:                              1.3,
    h6:                              1.3,
    alt:                             1.5,
    small:                           1.5,
);

// Grids
// -----------------------------------------------------------------------------

$grid-gutter-width:                  space(.5);
$html-max-width:                     1000px;

// Components
// -----------------------------------------------------------------------------

// Buttons
$btn-border-radius:                  25px;
$btn-padding:                        space(.5) space(3);
$btn-font-size:                      font-size(button);
$btn-font-family:                    $font-family-base;
$btn-font-weight:                    font-weight(bold);

// Breadcrumbs
$breadcrumb-separator-icon:          '–';

// Tooltip
$tooltip-bg-color:                   #ea401e;
$tooltip-text-color:                 color(light);

// Forms
// Base
$forms-input-color:                  color(gray, darker);
$forms-input-border-radius:          0;
$forms-input-focus-border-color:     color(primary);
$forms-input-focus-bg-color:         color(gray, lighter);
$forms-input-placeholder-color:      color(gray);

$custom-input-border-color--checked: color(primary);
$custom-input-color--checked:        color(primary);
$custom-control-indicator-size:      1.25rem;
$custom-select-indicator-icon:       url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEyOSAxMjkiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5Ij4NCiAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJtMTIxLjMsMzQuNmMtMS42LTEuNi00LjItMS42LTUuOCwwbC01MSw1MS4xLTUxLjEtNTEuMWMtMS42LTEuNi00LjItMS42LTUuOCwwLTEuNiwxLjYtMS42LDQuMiAwLDUuOGw1My45LDUzLjljMC44LDAuOCAxLjgsMS4yIDIuOSwxLjIgMSwwIDIuMS0wLjQgMi45LTEuMmw1My45LTUzLjljMS43LTEuNiAxLjctNC4yIDAuMS01Ljh6Ii8+DQo8L3N2Zz4NCg==');

$logo-height: 50px;
$header-height: $logo-height + space(3);

// variables offres
$border-radius-offre: 5px;
$offre-width: 360px;
