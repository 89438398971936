.cm-top-offres {
  display: grid;
  grid-gap: space(0.25);
  grid-template-areas:
    ". . ."
    "detail detail detail";
  grid-template-columns: minmax(0, 33.33%) minmax(0, 33.33%) minmax(0, 33.33%);
  margin: 0 space(0.5) space(2);

  @include smmdlg {
    margin: 0 space(2) space(2);
  }

  @include mdlg {
    display: flex;
  }
}

.offer_label {
  border-radius: space(0.25);
  min-width: 80%;
  width: auto;

  &.offer_label_primary {
    background-color: color(primary);
    font-size: font-size(extrasmall);

    @include mdlg {
      border-radius: 8px 8px 0 0;
      font-size: font-size(small);
    }
  }

  @include mdlg {
    top: -1rem;
    min-width: 70%;
  }
}

.wrap-offre {
  display: contents;

  @include mdlg {
    display: block;
    margin: 26px space(1.5) 0;
    max-width: $offre-width;
    width: calc(100% / 3);
  }

  &:hover {
    .r--primary {
      background: color(primary);
      color: color(light);
      text-decoration: none;
    }

    r-box--footer {
      .r--primary {
        background: color(gray, lighter);
        color: color(primary);
      }
    }
  }

  .r--famille {
    @include xs {
      display: none;
    }
  }

  .r--listing {
    li {
      position: relative;
    }
  }

  .r--notif-info {
    position: relative;
    // right: 0;
    float: right;
  }
}

r-tooltip {
  background-color: #fafaff;
  border: 1px solid color(primary);
  border-radius: 6px;
  color: color(primary);
  font-size: 15px;
  font-family: $font-family-base;
  z-index: 1;

  &.r--right::before {
    border-color: transparent color(primary) transparent transparent;
  }

  &.r--bottom::before {
    border-color: transparent transparent color(primary);
  }

  &.r--left::before {
    border-color: transparent transparent transparent color(primary);
  }

  &.r--top::before {
    border-color: color(primary) transparent transparent;
  }

  &.r--bottom,
  &.r--top {
    min-width: 150px;
  }

  &.r--left {
    width: 200px;

    @include smmdlg {
      width: auto;
    }
  }
}

//blocs offres
r-box {
  &.r--offre {
    background: color(light);
    border: 1px solid color(blue-gray);
    border-radius: $border-radius-offre;
    box-shadow: 0 0 100px color(blue-gray);
    display: contents;

    @include mdlg {
      border: 1px solid color(blue-gray);
      display: flex;
      height: 100%;
      padding: 0;
    }

    &:hover {
      //background: color(gray, lighter);
    }

    .r--famille & {
      align-items: stretch;
      flex-direction: row;
    }

    r-box--body {
      background: color(light);
      border: 1px solid transparent;
      box-shadow: 0 0 20px color(gray, light);
      grid-area: 1;
      position: relative;

      @include xs {
        margin: 0 space(0.25);
      }

      @include mdlg {
        background: none;
        border: 0;
        border-radius: $border-radius-offre $border-radius-offre 0 0;
        box-shadow: none;
        padding: space(2) 0 space(0.75);
      }

      .box-swg {
        display: none;

        @include mdlg {
          display: block;
          z-index: 102;
          padding-top: 1.2rem;
          position: relative;
        }

        .swg-button {
          display: inline-block;
          width: calc(100% - 60px);
          margin-left: 5%;
          margin: 1rem auto 0;
        }

        &::after {
          background-color: color(light);
          color: color(gray, base);
          content: "ou";
          display: inline-block;
          position: absolute;
          text-align: center;
          width: 40px;
          margin: auto;
          left: 0;
          right: 0;
          top: 0.5rem;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          text-align: center;
          max-width: 200px;
          background-color: color(gray, light);
          height: 1px;
          margin: auto;
          left: 0;
          top: 1.2rem;
          right: 0;
          width: 80%;
        }
      }

      .r-btn {
        display: none;

        @include mdlg {
          display: inline-block;
        }
      }

      h2 {
        font-weight: bold;

        @include xssm {
          font-size: 3.5vw;
        }
      }
      h4 {
        font-weight:300;
        color: color(primary);
        margin: space(0.5) auto;
      }
    }

    h2 {
      align-items: center;
      border: none;
      color: color(primary);
      display: flex;
      font-size: 3.5vw;
      justify-content: center;
      line-height: 1;
      min-height: 66px;
      padding-top: space(0.5);

      @include smmdlg {
        font-size: font-size(h2);
      }

      @include mdlg {
        display: block;
        margin: 0;
        min-height: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    .r-btn {
      &::before {
        content: "Jetzt abonnieren";
      }
    }

    .img-responsive {
      border-radius: 10px;
      display: none;

      @include mdlg {
        display: block;
        margin: auto;
      }
    }

    &.premium {
      border: 1px solid color(primary);

      @include smmdlg {
        border: 4px solid color(primary);
      }

      h2 {
        @include mdlg {
          // color: color(secondary);
        }
      }

      r-box--body {
        position: relative;

        @include xssm {
          border: 1px solid color(primary);
        }

        @include mdlg {
          background-color: color(lightblue);
        }


        // &::before {
        //     background: color(primary);
        //     border-radius: $border-radius-offre $border-radius-offre 0 0;
        //     color: color(light);
        //     content: 'Recommandé pour vous';
        //     font-family: $font-family-base;
        //     font-size: 2vw;
        //     left: 5%;
        //     line-height: 1;
        //     position: absolute;
        //     padding: 6px space(.5);
        //     top: -1px;
        //     transform: translateY(-100%);
        //     white-space: nowrap;
        //     width: 90%;

        //     @include smmdlg {
        //         font-size: 0.7rem;
        //     }

        //     @include mdlg {
        //         left: 50%;
        //         padding: 9px space(.5);
        //         top: -30px;
        //         transform: translate(-50%);
        //         width: 70%;
        //     }
        // }
      }

      r-box--footer {
        background-color: transparent;
      }
    }

    r-box--footer {
      color: color(primary);
      display: none;
      grid-area: detail;
      height: inherit;
      text-align: center;

      @include mdlg {
        background-color: color(lightblue);
        border-top: 1px solid color(gray, light);
        border-radius: 0 0 $border-radius-offre $border-radius-offre;
        display: block;
        //height: auto;
        padding: space(2);
        .r--famille & {
          height: auto;
        }
      }

      .box-swg {
        display: block;
        z-index: 1;

        @include mdlg {
          display: none;
        }
      }

      ul {
        margin: auto;
        max-width: 300px;
        padding: 0;
        text-align: left;

        @include mdlg {
          max-width: none;
        }
      }

      li {
        list-style: none;
        padding-bottom: space();
        padding-left: space(1.5);
        position: relative;

        &::before {
          color: color(pink);
          content: $icon-check;
          // display: inline-block;
          font-family: main;
          font-size: font-size(extrasmall);
          left: 0;
          line-height: 1.3;
          margin-bottom: space(0.5);
          margin-right: space(0.5);
          position: absolute;
          top: 3px;
        }
      }

      .group_btn_sticky {
        background-color: color(gray, lighter);
        bottom: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        left: 0;
        padding: 0;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: 100;

        @include mdlg {
          background: none;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0);
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          width: 100%;
        }

        &::before {
          box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
          content: "";
          height: 100%;
          left: calc(-16px + -8px);
          overflow: hidden;
          position: absolute;
          top: 0;
          width: calc(100% + 16px + 16px + 8px + 8px);

          @include sm {
            left: calc(-16px + -32px);
            width: calc(100% + 16px + 16px + 32px + 32px);
          }

          @include mdlg {
            content: none;
          }
        }

        .r-btn.r--primary {
          background: none;
          box-shadow: 0 0 10px rgba(color(dark), 0);
          margin-bottom: 0;
          position: relative;

          &::after {
            display: none;
          }
        }
      }

      .r-btn {
        background: color(gray, lighter);
        border-radius: 0;
        bottom: 0;
        box-shadow: 0 0 10px rgba(color(dark), 0.1);
        color: color(primary);
        display: inline-block;
        left: 0;
        margin-bottom: 0;
        padding: space() 0;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: 100;

        &:hover {
          background: color(gray, lighter);

          @include xs {
            background: color(gray, lighter);
          }

          &::before {
            opacity: 0.8;
          }
        }

        &:before {
          background: color(secondary);
          border-radius: 15px;
          display: inline-block;
          font-size: font-size(h4);
          padding: space(0.75) space(2);
        }

        // &:after {
        //   content: "sans engagement";
        //   display: block;
        //   font-family: $font-family-base;
        //   font-weight: 300;
        //   margin-top: space(0.25);
        // }

        @include mdlg {
          display: none;
        }
      }

      .swg-button {
        margin-top: 0;
      }

      .r--famille & {
        @include mdlg {
          border-top: none;
          border-left: 1px solid color(gray, light);
          border-radius: 0 $border-radius-offre $border-radius-offre 0;
          display: flex;
        }
      }
    }

    &.-is-open {
      r-box--body {
        @include xssm {
          border: 1px solid color(primary);
        }
      }

      r-box--footer {
        @include xssm {
          display: block;
        }
      }

      h2 {
        @include xssm {
          background: color(secondary);
        }
      }
    }
  }
}

.price {
  align-items: baseline;
  background: none;
  border-radius: 0;
  color: color(primary);
  display: flex;
  flex-flow: wrap;
  font-family: $font-family-base;
  /*grid-template-areas:
        "prix-barre euro suffixe"
        "prefixe prefixe prefixe";*/
  grid-template-columns: minmax(auto, 100%) minmax(auto, 100%);
  justify-content: center;
  margin: space(0.25) space(0.25) space(0.5) space(0.25);

  @include mdlg {
    grid-template-columns: minmax(auto, 65%) minmax(auto, 65%);
    margin: space() auto space(2);
  }

  .cm-form-left & {
    display: none;
  }

  strong {
    padding: 0;


    span {
      vertical-align: initial;
    }
  }
}

.price-euro {
  // font-family: $font-family-alt;
  font-size: 1.9rem;
  grid-area: euro;
  line-height: 2rem;
  margin-top: 20px;
  order: 2;
  text-align: center;

  @include smmdlg {
    font-size: 2.5rem;
  }

  @include mdlg {
    text-align: right;
  }

  .cm-form-left & {
    color: color(primary);
    font-size: 5rem;
    line-height: 3rem;

    @include smmdlg {
      font-size: 6rem;
      line-height: 4rem;
    }
  }
}

.price-centime {
  font-size: 0.9rem;

  @include smmdlg {
    font-size: 1.8rem;
  }

  &::before {
    content: ",";
  }

  &::after {
    content: "€";
  }
}

.price-prefixe {
  align-self: flex-end;
  font-size: 2.5vw;
  font-weight: 700;
  grid-area: prefixe;
  margin-bottom: 0;
  padding-right: space(.5);
  order: 1;
  width: 100%;

  @include smmdlg {
    font-size: 1.2rem;
    width: auto;
  }
}

.price-suffixe {
  color: color(primary);
  font-size: font-size(small);
  font-weight: bold;
  grid-area: suffixe;
  line-height: 1.3;
  order: 3;
  text-align: left;

  @include smmdlg {
    font-size: font-size(h5);
    line-height: font-size(h5);
    margin-left: space(0.5);
    margin-top: space(.5);
  }

  .cm-form-left & {
    font-size: 1.5rem;
    line-height: normal;

    @include smmdlg {
      font-size: space(2);
    }
  }
}

.price-prefixe {
  +.price-euro {
    margin-top: 0;

    @include xs {
      margin-right: space(0.25);
    }
  }

  +.price-suffixe {
    margin-top: 0;
  }
}

.price-barre-prefixe {
  font-size: 2.5vw;
  grid-area: prefixe-barre;
  margin-top: space(0.25);
  order: 4;

  @include smmdlg {
    font-size: font-size(base);
    width: 100%;
  }
}

.price_barre {
  color: color(gray, light);
  font-size: 0.9rem;
  grid-area: prix-barre;

  position: relative;

  @include xs {
    width: 100%;
  }

  @include smmdlg {
    font-size: 2.5rem;
    margin-right: space(1.5);
  }

  &::after {
    content: "/";
    transform: rotate(19deg) translate(-50%);
    position: absolute;
    left: 50%;
    font-size: 150%;
    font-weight: 300;
    top: -4px;

    @include smmdlg {
      top: -10px;
    }
  }
}

.box-swg-hidden {
  display: none;
}

.box-swg {
  text-align: center;
}

.swg-button {
  margin-top: 14px;
  max-width: 220px;
}

.r-nbr-compte {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.85rem;
  font-weight: bold;
  justify-content: center;

  @include smmdlg {
    font-size: 1.3rem;
    margin-top: space();
  }

  r-icon {
    color: color(pink);
    font-size: 1.5rem;

    @include smmdlg {
      font-size: 1.7rem;
    }
  }

  p {
    margin-left: space(0.5);

    @include xs {
      width: auto;
    }
  }
}

.r--famille {
  @include xssm {
    display: none;
  }

  @media (min-width: 1320px) {
    max-width: calc((#{$offre-width} * 3) + (#{space(1.5)} * 4) + #{space(0.5)});
    margin: auto;
    width: calc(100% - #{space(4)});
  }

  @include mdlg {
    max-width: initial;
    margin: auto space(3.5);
    width: auto;
  }

  r-box--body,
  r-box--footer {
    height: auto;
    width: 50%;
  }
}