//
// Layout > Footer
//

r-footer {
    background: color(primary);
    color: color(light);
    padding:space() space(.5) space();
    padding-bottom: 6rem;

    @include smmdlg {
        align-items: center;
        display: flex;
        justify-content: center;        
        padding:space() space(.5) space(6);
    }

    .r--menu-menu-footer-le-soir {       
        display: block;
        font-size: font-size(extrasmall); 
        margin: 0;
        line-height: space(2); 
        text-align: center;
        
        @include mdlg {
            align-items: center;
            display: flex;            
            line-height: space(2); 
        }

        .r-menu--link {
            color: color(light);
            cursor: pointer;            
            font-weight: 300;
            padding: 0 space(.5);
            &:hover {
                background: none;
                text-decoration: underline;
            }
            
        }
    }
}

.r-footer--copyright {
    //font-family: $font-family-base;
    padding: 0;
}

.embeddedServiceHelpButton {
    display: none !important;

    @include smmdlg {
        display: inherit !important;
    }
}
