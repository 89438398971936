//
// Buttons switch
//

#btn_switch_div{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    #switch_commentaire {
        margin-bottom:50px;
        position: relative;

        .switch_commentaire_droite, 
        .switch_commentaire_gauche {
            color:color(primary);
            font-family: $font-family-alt-cursive; 
            font-size: 1.1rem;
            margin:0;
        }

        .switch_commentaire_droite {
            &::after {
                    content:$icon-arrow-up-right;
                    color:color(primary);
                    font-family: main;
                    font-size:1.5rem;
                    margin-left:10px;
                    position: absolute;
                    top: -10px;
            }
        }

        .switch_commentaire_gauche {
            &::before {
                    content:$icon-arrow-up-left;
                    color:color(primary);
                    font-family: main; 
                    font-size:1.5rem;
                    margin-right:10px;
                    position: absolute;
                    top: -10px;
                    left: -30px;
            }
        }
    }
}

.switch_offres {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px;

    h2 {
        color: black;
        font-size:1rem;
    }
    
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 26px;
        margin: 0 10px 0 10px;

        input { 
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: color(primary);
            transition: .4s;

            &::before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 4px;
                bottom: 4px;
                background-color: color(light);
                transition: .4s;
            }

            &.round {
                border-radius: 34px;

                &::before {
                    border-radius: 50%;
                }
            }
        }

        input:checked + .slider {
            background-color: color(primary);
        }
        input:checked + .slider:before {
            transform: translateX(24px);
        }
    }
}

.container-btn-switch {
	align-items: center;
    display: flex;
	justify-content: center;
    margin: 20px 0;
    position: relative;

    .switch-tabs {
        background-color: color(primary);
        border: 1px solid color(primary);;
        border-radius: 99px; 
        display: flex;
        padding: 1px;
        position: relative;

        * {
            z-index: 2;
        }

        input[type="radio"] {
            display: none;
        }
        
        .tab {
            align-items: center;
            border-radius: 99px;
            color: #fff;
            cursor: pointer;
            display: flex;
            font-size: 1.1rem;
            font-weight: 500;
            height: 35px;
            justify-content: center;
            margin:0;
            transition: color 0.15s ease-in;
            width: 160px;
        }
    
        
        input[type="radio"] {
            &:checked {
                & + label {
                    color: color(primary);
                    & > .notification {
                        background-color: #fff;
                        color: color(primary);
                        font-weight:bold;
                    }
                }
            }
        }
        
        input[id="radio-1"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(0);
                }
            }
        }
        
        input[id="radio-2"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(100%);
                }
            }
        }
        
        input[id="radio-3"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(200%);
                }
            }
        }
        
        .glider {
            background-color: #fff;
            border-radius: 99px;
            display: flex;
            height: 35px;
            position: absolute;
            transition: 0.25s ease-out;
            width: 160px;
            z-index: 1; 
        }
        
        @media (max-width: 700px) {
            .tabs {
                transform: scale(0.6);
            }
        }
    }
}



