.gr-one-col {

  .block-detail {
    flex: 3;
    position: relative;
    width: 100%;

    @include smmdlg {
      //background-color: color(gray, lighter);
      width: 65%;
    }

    .description {
      display: flex;
      flex-direction: column;
      font-size: 17px;
      justify-content: flex-start;
      margin: 0;
      text-transform: none;
    }

    // h2,
    // h3 {
    //  font-family: "ralewaybold", arial, sans-serif;
    // }

    h2 {
      color: color(primary);
      font-size: font-size(h4);
      font-weight: bold;
      margin: 0 0 space(.5);
      text-align: left;

      @include smmdlg {
        font-size: font-size(h3);
        //margin-top: 0;
      }
    }

    h3 {
      color: color(primary);
      font-family: $font-family-base;
      font-size: font-size(base);
      font-weight: 700;
      line-height: 1.2;
      margin-top: 5px;
    }

    p {
      margin: 0 0 space(.25);
      font-family: $font-family-title;

    }

    ul {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      margin: 0;
      padding-left: 0;
      &.arguDescription {
        flex-direction: row;
        flex-wrap: nowrap;
        li  {
          font-family: $font-family-title;
          font-size: font-size(small);

          
          &::before {
            color: color(pink);
            content: $icon-check;
            font-family: main;
            font-size: font-size(base);
            //left: 0;
            line-height: 1.3;
            margin-bottom: 8px;
            margin-right: 8px;
            //position: absolute;
            //top: 3px;
          }
        }
      }

      @include xs {
        flex-direction: column;
      }
    }

    li {
      // align-items: start;
      display: block;
      font-size: font-size(small);
      font-family: $font-family-title;
      list-style: none;
      margin: 0 0 space(.5) 0;
      padding-left: 0;
      width: 100%;
      &:before {
        content: '- ';
        padding-right: space(.5);
     }
      

      @include smmdlg {
        //width: 50%;
      }

      h4 {
        font-family: $font-family-base;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.2;
        margin: 0;
        @include smmdlg {
          padding-right: space(0.25);
        }
      }

      img {
        display: block;
        height: 46px;
        margin: 0 space(0.5) 0 0;
        width: 46px;

        @include smmdlg {
          height: 56px;
          //margin-right: space(.5);
          width: 56px;
        }
      }
    }
  }

  .node-webform {

    background-color: #fff;
    display: block;
    //margin-top: space(3);
    padding: space(1.5);

    @include smmdlg {
      border: 1px solid color(gray, light);
      margin-top: space(3);
      padding: space(2);
    }

    > h2 {
      font-size: space(1.5);
      font-weight: bold;
      // text-align: center;
      margin: 0 32px;
      width: 100%;

      @include smmdlg {
        font-size: 2.1rem;
      }
    }
  }

  .node-webform header {
    display: none;
  }

  fieldset {
    max-width: 100%;
  }
  .field-name-field-page-detail-title {
    display: block;
  }

  .field-name-field-descriptif-long {
    margin-bottom: 0;
    padding: 0;

    @include smmdlg {
      margin-bottom: 0;
      padding: space(0.5) space();
      //max-width: 860px;
    }
  }

  .webform-client-form {
    margin: 0;
    padding: 0;

    @include smmdlg {
      padding: space(0.75);
    }
  }

  .cm-form-content {
    margin: 0 auto;
    max-width: 600px + space(2);
    //padding: space(2);
  }

  .block-parent-offre {
    //border: 1px solid color(primary);
    //border-radius: space(.75);
    display: block;

    @include smmdlg {
      background: color(gray, lighter);
      border-radius: space(0.25);
      display: flex;
      flex-wrap: wrap;
      padding: space(1.5);
    }

    p {
      margin: 0;
      max-width: 100%;
      padding: 0;
    }

    img {
      display: block;
      margin: auto;
      max-width: 300px;

      @include xs {
        max-width: 90%;
      }
    }

    r-icon {
      color: color(pink);
      font-size: 2rem;
      padding: 0 space(0.5);

      @include smmdlg {
        font-size: 4rem;
        padding: 0 space();
      }
    }

    .paneljour {
      display: none;

      &.show {
        display: block;

        // #openAcc {
        //     transform: rotate(180deg);
        // }
      }
    }

    // #openAcc {
    //     color: $white;
    //     cursor: pointer;
    //     background-color: color(primary);
    //     border-radius: 50%;
    //     font-size: 1rem;
    //     padding: .5rem;
    //     position: absolute;
    //     top: 0rem;
    //     right: space(.5);

    //     @include mdlg {
    //         font-size: 2rem;
    //         right: 0rem;
    //     }

    //     &.show {
    //         transform: rotate(180deg);
    //     }
    // }

    .block-image {
      align-items: center;
      display: flex;
      flex: 2;

      @include xssm {
        display: none;
      }
    }
  }

  .cm-progressbar {
    width: 960px;
    max-width: 100%;
    display: none;

    @include xssm {
      width: 100%;
    }

    &:first-child {
      display: block;
      margin: space() auto;

      .cm-progressbar-page {
        display: block;
        margin: space() auto 0;

        &.current {
          background: none;

          .cm-progressbar-page-number {
            background: color(primary);
            color: color(light);
          }
        }
      }

      .cm-progressbar-outer {
        display: flex;
        max-width: 740px;
        margin: auto;
      }

      .cm-progressbar-page {
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
        text-align: center;
        flex: 1;
        font-size: font-size(base);
        font-weight: 300;
        font-family: $font-family-base;
        line-height: 1.2;
        color: color(primary);
        position: relative;

        @include xssm {
          font-size: font-size(small);
          padding: 0 space(0.5);
        }

        &:before,
        &:after {
          content: "";
          display: block;
          width: 50%;
          height: 4px;
          background: color(primary);
          position: absolute;
          top: 15px;

          @include smmdlg {
            top: 18px;
          }
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }

        &:first-child {
          &:before {
            background: color(light);
          }
        }

        &:last-child {
          &:after {
            background: color(light);
          }
        }

        &.current {
          font-size: font-size(base);
          font-family: $font-family-base;
        }
      }

      .cm-progressbar-page-number {
        //align-items: center;
        background: color(light);
        border: 1px solid color(primary);
        border-radius: 50%;
        color: color(primary);
        display: flex;
        font-size: font-size(h3);
        font-family: $font-family-base;
        font-weight: 700;
        height: 33px;
        justify-content: center;
        line-height: 1;
        margin: 0 auto space();
        position: relative;
        text-align: center;
        width: 33px;
        z-index: 30;

        @include smmdlg { 
          align-items: center;          
          display: flex;
          font-size: font-size(h4);
          height: 40px;
          width: 40px;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

// Icon mdp oeil
.gr-one-col,
.gr-two-col {
  .password-container {
    position: relative;

    .webform-conditional-hidden {
      & + #eye-open {
        display: none;
      }
    }
  }

  .glyphicon-eye-open {
    &:before {
      bottom: 10px;
      content: $icon-eye-open;
      font-family: main;
      font-style: initial;
      position: absolute;
      right: space(2);
      text-align: center;
      width: 40px;

      @include xssm {
        top: 35px;
      }
    }

    &.glyphicon-eye-close {
      &:before {
        bottom: 10px;
        content: $icon-eye-closed;
        font-family: main;
        font-style: initial;
        position: absolute;
        right: space(2);
        text-align: center;
        width: 40px;

        @include xssm {
          top: 35px;
        }
      }
    }
  }
}

.gr-two-col {
  &.two-equal-columns {
    .cm-form-left {
      @include mdlg {
        padding: space() space(2);
        max-width: 100%;
      }
    }
  }
  .cm-form-left {
    @include mdlg {
      padding: space() space();
      max-width: 277px;
    }
  }
}
