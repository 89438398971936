// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

r-p {
    &.r--label {
        color: color(primary);
        display: inline-block;
        font-family: $font-family-alt;
        font-weight: bold;
        font-size: font-size(h5);
        vertical-align: top;
        width: 40%;

        @include smmdlg {
            width: auto;
        }
    }

    &.r--description {
        display: inline-block;

        .r-table--cell & {
            max-width: 50%;

            @include smmdlg {
                max-width: none;
            }
        }
    }

    &.r--strong {
        color: color(primary);
        font-family: $font-family-alt;
        font-weight: bold;
        font-size: font-size(h5);
    }
}
