//global
.node-type-kiosque {
    .cm-main-title {
        h1 {
            font-family: $font-family-base;
            font-size: 40px;
            margin-bottom: 0;
            @include sm {
                margin-top: 0;
            }
        }
    }

    .cm-form-left {
        margin: 0 10px 10px;
        padding: 0;
        position: relative;
        width: calc(66% - 60px);

        @include md {
            margin: 0 15px;
            width: calc(66% - 30px);
        }

        @include xssm {
            // @include clearfix;
            clear: both;
            margin: auto;
            width: auto;
        }

        @include smmdlg {
            margin-left: 45px;
            margin-right: 30px;
            margin-top: 0;
            padding: 150px 15px 15px;
        }
    }

    .cm-form-right {
        margin: 0 10px;
        padding: 15px;
        width: calc(34% - 60px);

        @include md {
            margin: 0 15px 0 0;
            width: calc(34% - 15px);
        }

        @include xssm {
            margin: 10px auto;
            width: auto;
        }

        @include smmdlg {
            margin-left: 0;
            margin-top: 0;
        }
    }

    .cm-form-content {
        padding: 15px 0 0;
    }

    .cm-progressbar {
        margin: 0 -15px;
    }

    .onglets-paiement {
        .panel-heading {
            display: none;
        }

        .panel-body {
            padding-top: 0;

            .tab-pane {
                border-bottom: 5px solid color(gray, light);
                display: block !important;
                margin-bottom: 30px;
                padding-bottom: 30px;

                &:last-child {
                    border: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    .field-name-field-visuel-grand {
        width: 100%;

        .img-responsive {
            box-shadow: 0 0 1px color(gray, light);
            transform: none;
            width: 80%;
            margin-left: 15%;
            margin-top: 15px;

            @include smmdlg {
                margin-top: 45px;
            }
        }
    }

    .field-name-field-prix-affiche {
        background: color(secondary);
        color: color(primary);
        font-family: $font-family-title;
        font-size: 45px;
        right: -10px;
        top: -20px;
        margin: 0;
        position: absolute;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        border: 3px solid color(light);

        @include smmdlg {
            font-size: 55px;
            left: 50%;
            right: auto;
            top: 120px;
        }
    }

    .cm-kiosque-title {
        font-size: 25px;
        position: static;
        top: 5px;
        left: 0;
        right: 0;

        @include smmdlg {
            font-size: 35px;
            position: absolute;
        }

        strong {
            color: color(secondary);
        }
    }

    .cm-kiosque-wrap {
        float: none;
        width: auto;

        @include smmdlg {
            float: right;
            width: 50%;
        }
    }

    .field-name-field-descriptif-long {
        border: 0;
        float: none;
        padding-right: 10px;
        width: auto;

        @include smmdlg {
            border-right: 1px solid color(secondary);
            //float: left;
            width: 50%;
        }

        img {
            width: 72%;
            display: block;
            margin: auto;
        }
    }

    .main-container {
        max-width: 1980px;
    }
}
