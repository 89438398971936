html {
    height: 100%;
}

.page-payment-accept {
  // r-header {
  //   grid-template-rows: 38px;
  //
  //   @include mdlg {
  //     grid-template-areas:
  //       "logo slogan sso";
  //     grid-template-columns: minmax(0,200px) minmax(0,auto) minmax(0,230px);
  //   }
  // }

  .webform-confirmation {
    border: 1px solid color(gray, light);
    padding: 0;
    margin-bottom: space(2);

    .webform-confirmation {
      border: none;
      max-width: 90%;
      margin: 5% auto;
      position: relative;
      z-index: 10;

      // &:after {
      //   content: " ";
      //   position: absolute;
      //   z-index: -1;
      //   bottom: 0px;
      //   left: 15%;
      //   width: 70%;
      //   height: 50px;
      //   border-radius: 125px / 12px;
      //   box-shadow: 0 0 40px color(gray);
      // }

      h1 {
        text-transform: uppercase;
        font-family: $font-family-alt;
        font-weight: bold;
        margin-bottom: space();
        color: color(primary);
      }

      h3 {
        font-family: $font-family-base;
        font-weight: bolder;
        text-transform: uppercase;
      }

      p {
        color: color(gray, darker);
        max-width: 100%;
        font-size: font-size(h5);

        strong {
          color: color(primary);
        }
      }

      a {
        font-size: font-size(small);
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      .cm-thank-btn {
        background: none;
        display: none
      }
    }
  }
}

.page-node-prospect-confirmation {

  .webform-confirmation {
    border: 1px solid color(gray, light);
    padding: 0;
    margin-bottom: space(2);

    .webform-confirmation {
      border: none;
      max-width: 90%;
      margin: 5% auto;
      position: relative;
      z-index: 10;

      // &:after {
      //   content: " ";
      //   position: absolute;
      //   z-index: -1;
      //   bottom: 0px;
      //   left: 15%;
      //   width: 70%;
      //   height: 50px;
      //   border-radius: 125px / 12px;
      //   box-shadow: 0 0 40px color(gray);
      // }

      h1 {
        text-transform: uppercase;
        font-family: $font-family-alt;
        font-weight: bold;
        margin-bottom: space();
        color: color(primary);
      }

      h3 {
        font-family: $font-family-base;
        font-weight: bolder;
        text-transform: uppercase;
      }

      p {
        color: color(gray, darker);
        max-width: 100%;
        font-size: font-size(h5);

        strong {
          color: color(primary);
        }
      }

      a {
        font-size: font-size(small);
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      .cm-thank-btn {
        background: none;
        display: none
      }
    }
  }
}

.lesoir-prospect {
    background: #242424 url('/sites/all/themes/custom/boutique/bootstrapMarketingLeSoir/img/fond.jpg') top center no-repeat;
    height: 100%;

    r-wrapper,
    .bootstrap-onecol-stacked {
        background: none;
    }

    .main-container {
        background: none;
        min-height: 100%;
    }

    .webform-confirmation {
        background: color(primary);
        margin: 60px auto;
        max-width: 480px;

        .cm-thank {
            background: url('/sites/all/themes/custom/boutique/bootstrapMarketingLeSoir/img/lesoir-plus.svg') top center no-repeat;
            background-size: 270px 80px;
        }

        &.cm-thank-msg {
            background: color(light);
            margin-bottom: 0;
        }
    }

    .form-actions {
        padding: 0 5px;
    }

    .btn {
        width: 100%;
    }

    .node-webform {
        background: color(primary);
        border-radius: 10px;
        margin: space(2) auto;
        max-width: 480px;

        header {
            background: url('/sites/all/themes/custom/boutique/bootstrapMarketingLeSoir/img/lesoir-plus.svg') center center no-repeat;
            background-size: 63%;
            height: 80px;

            h2 {
                display: none;
            }
        }

        .webform-client-form {
            background: color(light);
            border-radius: 5px;
            margin: 0 space(.5) 20px;
            padding: space();
        }

        .form-type-radio {
            display: inline-block;
        }

        .webform-component--fieldset-identification--markup-connexion {
            display: inline-block;
            margin-right: space();

            p {
                margin: 0;
            }
        }

        .webform-component--fieldset-identification--markup-creation-compte {
            margin-top: space(.5);
        }
    }
}

// .page-etape2 {
//   r-header {
//     grid-template-rows: 38px;

//     @include mdlg {
//       grid-template-areas:
//         "logo slogan sso";
//       grid-template-columns: minmax(0,200px) minmax(0,auto) minmax(0,230px);
//     }
//   }
// }
