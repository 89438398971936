//
// Layout > Sidebar
//

// Sidebar on mobile
//

r-sidebar {
    &.r--main-sidebar {
        @include mdlg {
            float: left;
            width: 25%;
        }

        &.-is-open {
            background: color(gray, lighter);
            top: ($logo-height / 2) + space(.5) + 32px;
        }
    }
}
