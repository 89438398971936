//
// Modal
//

// Modal > Modal Content
//

r-modal {
    &.-is-open {
        z-index: 100;
    }
}

r-modal--content {
    display: block;

    r-box--header,
    r-box--body,
    r-box--footer {
        margin: 0;
    }
}
