//
// Menu
//

// Base styles
//

r-menu {
    display: flex;
    // margin-bottom: space(2);
}

r-menu--item,
r-submenu--item {
    display: block;

    &.-has-submenu {
        display: flex;
        flex-wrap: wrap;
    }
}

r-menu--item {
    &.-is-open {
        r-submenu {
            max-height: 500px;
        }
    }
}

r-submenu {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s linear;
    width: 100%;
}

.r-menu--link {
    display: block;
    flex: 1;
}

// Menu = hamburger btn
//

r-menu-btn {
    cursor: pointer;
    display: block;
    float: left;
    height: 35px;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;

    @include mdlg {
        display: none;
    }

    &.-is-open {
        r-menu-btn--bar {
            &:first-child {
                top: 0;
                transform: rotate(45deg) translateX(10px) translateY(10px);
            }

            &:nth-child(2) {
                opacity: 0;
                transform: translateX(-10px);
            }

            &:last-child {
                top: 0;
                transform: rotate(-45deg) translateX(-10px) translateY(10px);
            }
        }
    }
}

r-menu-btn--bar {
    display: block;
    height: 4px;
    position: absolute;
    transform-origin: 50% 50%;
    transition: all .7s cubic-bezier(.77, 0, .175, 1);
    width: 35px;

    &:first-child {
        top: 4px;
    }

    &:nth-child(2) {
        top: 15px;
    }

    &:last-child {
        top: 27px;
    }
}

// Menu expand btn
//

r-menu--expand {
    cursor: pointer;
    display: inline-block;
    text-align: center;
}
