.cm-thank {
    //background: color(fond);
    margin: auto;
    max-width: $html-max-width;
    padding: space(2);

    .cm-thank-msg {
        p {
            margin: space(.25) 0 space();
        }
    }

    .cm-thank-btn {
        text-align: center;
    }

    .btn {
        color: color(gray, darker);
        display: inline-block;
        padding: 5px 20px;
        width: auto;
    }

    p {
        max-width: 100%;
    }

    .cm-sepa-btn {
        background-color: #f2bf0c;
        border-radius: 5px;
        height: 50px;
        padding: 10px;
        text-decoration: none;
        width: 250px;

        span {
            color: black;
            font-size: 16px;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
        }
    }

    &.prelev-auto {
        padding: 0 space(2);
    }
}
