.charlie {
  background: color(light);
  font-family: $font-family-alt;

  h2,
  h3 {
    font-family: $font-family-alt;
  }

  h2 {
    display: none;
  }

  h3 {
    // border-bottom: 1px solid color(gray, light);
    color: color(primary);
    font-size: 30px;
    margin-bottom: 20px;
    // margin-top: 0;
    // padding-bottom: 10px;
    text-align: center;

    .icon {
      color: #09b081;
      font-size: 70px;
    }
  }

  p {
    color: color(gray, dark);
    margin: 0 auto 20px;
    max-width: 540px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .call_center,
  #cm-main-menu,
  #cm-menu-formule {
    display: none;
  }

  .page-payment-cardrenew article,
  .node-type-page article {
    margin: auto;
    padding: 0;
  }

  .cm-form-left,
  .cm-form-right {
    box-shadow: none;
    float: none;
    margin: auto;
    width: auto;
  }

  .btn {
    color: color(primary);
    font-family: $font-family-alt;
    font-size: 18px;
    font-weight: bold;
  }

  .btn-secondary {
    background: color(gray, lighter);

    &:hover {
      background: color(gray, light);
    }
  }

  .main-container {
    background: color(light);
  }

  .pane-content {
    background: color(light);
    // margin: $grid-gutter-width auto;
    padding: 1px $grid-gutter-width;
  }

  .form-actions .webform-submit.btn,
  .form-actions .webform-next.btn {
    font-family: $font-family-alt;
    margin-left: auto;
    margin-right: auto;
  }

  .webform-confirmation {
    padding: 0;
  }

  .cm-thank {
    h3 {
      margin: 0;
    }

    p {
      text-align: center;
    }
  }
}
