// -----------------------------------------------------------------------------
// Base
// -----------------------------------------------------------------------------

* {
    transition: background .3s ease;
}

button {
    border: 0;
}

section {
    margin-bottom: space(2);

    @include smmdlg {
        margin-bottom: space(3);
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

hr {
    background: color(gray, light);
    border: none;
    height: 1px;
    margin-bottom: space();
}

label {
    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
