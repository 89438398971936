// form {
//     .form-checkboxes,
//     .form-radios {
//         label {
//             font-family: $font-family-base;
//         }
//     }
// }

// .form-group {
//     margin: 8px 0 0;

//     label {
//         &.control-label {
//             color: color(gray, dark);
//             font-weight: 300;
//         }
//     }

//     &.label-static,
//     &.label-floating.is-focused,
//     &.label-floating:not(.is-empty) {
//         label.control-label {
//             top: -25px;
//         }
//     }
// }

// .form-control[readonly],
// .form-group .form-control[readonly] {
//     background: color(gray, lighter);
//     border-bottom: 1px solid #d2d2d2;
// }

//fieldset
fieldset {
    &.panel-default {
        border: 0;
        box-shadow: none;
        margin: 0;

        fieldset {
            margin-bottom: 30px;

            legend {
                a {
                    // font-size: 21px;
                }
            }
        }

        .panel-heading {
            background: none;
            border: 0;
            // font-family: $font-family-title;
            // font-weight: bold;
            margin-bottom: 10px;
            top: 10px;

            span {
                font-size: 1.5rem;
            }
        }

        // .panel-body {
        //     padding: 15px 15px 0;

        //     @include xssm {
        //         padding: 10px 5px 0;
        //     }
        // }
    }

    &.hide-tabs {
        .nav-tabs {
            display: none;
        }
    }
}

//legend
// .panel {
//     &,
//     &.panel-default {
//         & > .panel-heading {
//             background: none;
//             padding: 0;
//         }
//     }
// }

//bouton radio
// .radio {
//     margin-bottom: 5px;

//     input[type='radio'] {
//         height: auto;
//         left: -13px;
//         position: relative;
//         width: auto;
//     }
// }

// .webform-client-form {
//     .webform-component-radios {
//         > .control-label {
//             font-family: $font-family-alt;
//             font-size: 21px;
//             margin: 0;
//         }

//         .form-radios {
//             // display: block;
//             margin-left: 30px;
//         }

//         .radio {
//             margin: 0;

//             .control-label {
//                 font-size: $font-size-base;
//                 line-height: 19px;
//                 margin: 8px 0 0;
//             }
//         }
//     }
// }

//checkbox
// .checkbox {
//     margin-bottom: 5px;
//     margin-left: 15px;

//     input[type='checkbox'] {
//         height: auto;
//         width: auto;
//     }

//     .checkbox-material {
//         display: inline-block;
//     }
// }

// .form-checkboxes {
//     .check {
//         margin-right: 15px;
//     }

//     .checkbox {
//         .control-label {
//             margin-top: 0;
//         }
//     }
// }

//select
.webform-component-select,
.form-type-select {
    background: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/arrow-down.svg') bottom 25px right 8px no-repeat;
    background-size: 12px;
    position: relative;
}

.form-select {
    appearance: none;
}

//select images
.webform-component-select-images {
    .control-label {
        padding-left: 0;
    }

    .form-radios {
        display: flex;
        flex-wrap: wrap;
    }

    .radio {
        margin: 0 1%;
        width: 48%;

        input[type='radio'] {
            ~ .img-responsive {
                margin: 5px;
            }

            &:checked {
                ~ .img-responsive {
                    border: 5px solid color(primary);
                    margin: 0;
                }
            }
        }
    }
}

//date
.cm-date {
    @include mdlg {
        display: flex;
    }

    label {
        &.control-label {
            margin-top: 0;
            padding-left: 0;

            @include mdlg {
                flex: 0 0 100%;
            }
        }
    }

    .webform-calendar,
    .form-item {
        margin-right: 10px;
        margin-top: 7px;
        padding: 0;

        @include mdlg {
            flex: 0 0 25%;
        }
    }

    .webform-calendar {
        margin-top: 17px;
        max-width: 18px;
        padding: 0;
    }
}

//heure
.cm-time {
    .webform-container-inline {
        select {
            display: inline;
            width: 30%;
        }

        .form-item {
            display: inline;
        }
    }
}

//adresse belge - libraire
.webform-component--libraire,
.cm-adresse-belge {
    &.panel-default {
        .panel-heading {
            margin-bottom: 0;

            span {
                color: color(primary);
                font-size: 1.8rem;
            }
        }

        .panel-body {
            padding-left: 0;
            padding-top: 0;
        }
    }
}

.webform-component-belgian-address {
    float: left;
    padding: 0 5px 0 0;
}

.col-md-4 {
    width: 33%;
}

.col-md-5 {
    width: 40%;
}

.col-md-7 {
    width: 60%;
}

.col-md-8 {
    width: 67%;
}

//radio images
.cm-radio-image {
    img {
        border: 3px solid transparent;
    }

    input[type='radio'] {
        &:checked ~ img {
            border: 3px solid color(secondary);
        }
    }

    .form-radios {
        @include smmdlg {
            display: flex;
        }
    }

    .radio {
        margin: 0;

        @include smmdlg {
            flex: 0 0 50%;
        }
    }
}

//description offre
.field-name-field-visuel-grand {
    margin: 0 0 15px;
    padding: 0 0 10px;

    img {
        display: block;
        margin: auto;
    }
}


.field-name-field-layout-personnalise,
.field-name-field-couleur-du-layout {
    display: none;
}

//fichier
.webform-component-file {
    margin: 10px 0;

    .btn.btn-default {
        background: color(button);
        color: color(light);
        font-size: 14px;

        &::before {
            content: $icon-upload-button;
            font-family: icons;
            margin-right: 7px;
            font-size: 12px;
            position: relative;
            top: 1px;
        }
    }

    .btn.btn-danger {
        background: color(primary);
        color: color(light);

        &:hover {
            background: lighten(color(primary), 10%); 
            color: color(light);
            text-decoration: underline;
        }
    }

    .description {
        display: none;
    }

    input[type=file] {
        line-height: 1;
        opacity: 1;
        position: static;
    }

    &.form-group {
        label.control-label {
            // background: color(gray, lighter);
            line-height: 30px;
            margin: 0;
            // padding: 3px 10px;
            // width: 73%;
        }
    }

    .form-control {
        &.form-file {
            // bottom: auto;
            // height: 34px;
            border: 0;
            margin: 0;
            width: 80%;
        }
    }

    .form-submit {
        background: color(primary);
        color: color(light);
        margin: space() 0;
        position: relative;
        width: auto;
        
        &:hover {
            background: lighten(color(primary), 10%); 
        }

        @include mdlg {
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 150;
        }
    }

    .description {
        text-transform: none;

        a {
            text-decoration: underline;

            .glyphicon-question-sign {
                display: none;
            }
        }
    }
}

.popover {
    background: color(light);

    .popover-title {
        color: color(gray, darker);
    }
}

//input type number
input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
}

//règles backend
// .small {
//     &.form-group {
//         label {
//             &.control-label {
//                 font-size: 12px;
//                 line-height: 13px;

//                 strong {
//                     font-size: 12px;
//                     line-height: 13px;
//                 }
//             }
//         }
//     }
// }

.cm-label-bold {
    label {
        &.control-label {
            font-family: ralewaybold;
        }
    }
}

#edit-submitted-adresse-facturation-adresse-francaise {
    .panel-body {
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        grid-template-rows: auto;

        &::before {
            display: none;
        }

        .form-item {
            width: 100%;
        }

        .bg-success {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
            width: 100%;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-code-postal {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-ville {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-numero-rue {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-nom-rue {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-override-validation {
            display: inline-block;
            grid-row-start: 6;
            grid-column-end: 5;
            grid-column-start: 1;

            .control-label {
                font-size: 16px;
                margin-top: 8px;
            }
        }
    }
}

#edit-submitted-adresse-livraison-adresse-francaise {
    .panel-body {
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        grid-template-rows: auto;

        &::before {
            display: none;
        }

        .form-item {
            width: 100%;
        }

        .bg-success {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
            width: 100%;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-code-postal {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: auto;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-override-validation {
            display: inline-block;
            grid-row-start: 6;
            grid-column-end: 5;
            grid-column-start: 1;

            .control-label {
                font-size: 16px;
                margin-top: 8px;
            }
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-ville {
            grid-column-start: 2;
            grid-column-end: 5;
            grid-row-start: auto;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-numero-rue {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-nom-rue {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-boite {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-cedex {
            grid-column-start: 3;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-complement-adresse {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
        }
    }
}
