.tooltip {
  display: block;
  font-size: font-size(small);
  line-height: 1.4;
  opacity: 0;
  position: absolute;
  visibility: visible;
  z-index: 1070;

  &.in {
    opacity: 0.9;
  }
  &.top {
    padding: 5px 0;
    margin-top: -3px;

    .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 5px 5px 0;
      border-top-color: color(tooltip);
    }
  }
  &.right {
    padding: 0 5px;
    margin-left: 3px;

    .tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-width: 5px 5px 5px 0;
      border-right-color: color(tooltip);
    }
  }
  &.bottom {
    padding: 5px 0;
    margin-top: 3px;

    .tooltip-arrow {
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: color(tooltip);
    }
  }
  &.left {
    padding: 0 5px;
    margin-left: -3px;

    .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
      border-left-color: color(tooltip);
    }
  }

  &.top-left .tooltip-arrow {
    bottom: 0;
    left: 5px;
    border-width: 5px 5px 0;
    border-top-color: color(tooltip);
  }
  &.top-right .tooltip-arrow {
    right: 5px;
    bottom: 0;
    border-width: 5px 5px 0;
    border-top-color: color(tooltip);
  }

  &.bottom-left .tooltip-arrow {
    top: 0;
    left: 5px;
    border-width: 0 5px 5px;
    border-bottom-color: color(tooltip);
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    right: 5px;
    border-width: 0 5px 5px;
    border-bottom-color: color(tooltip);
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: color(light);
  text-align: center;
  text-decoration: none;
  background-color: color(tooltip);
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
