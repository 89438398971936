.cm-top-offres {
    display: flex;
    justify-content: center;

    // @include xssm {
    //     flex-wrap: wrap;
    // }
}

//blocs offres
.wrap-offre {
    display: flex;
    margin: 0 space(.25);
    max-width: 270px;
    width: 33.33%;

    @include mdlg {
        margin: 0 space(2);
    }

    &:hover {
        .btn_continuer {
            visibility: visible;
        }
    }

    .btn_continuer {
        visibility: hidden;
    }
}

r-box {
    &.r--offre {
        background-color: color(light);
        display: flex;
        flex-direction: column;
        position: relative;
        transition: all .5s ease;
        width: 100%;

        @include mdlg {
            padding: space();
        }

        &:hover {
            @include mdlg {
                background-color: color(gray, lighter);
            }
        }

        &.-is-open {
            r-box--body {

                h4,
                h2 {
                    @include xssm {
                        color: color(light);
                    }
                }

                h2 {
                    font-weight: bold;
                }
            }
        }

        r-box--body {
            padding: 0;
            text-align: center;

            h4,
            h4:first-of-type {
                @include xssm {
                    display: none;
                }
            }

            h2 {
                @include xssm {
                    display: flex;
                    font-size: 22px;
                    font-weight: normal;
                    min-height: auto;
                    padding-top: space(1.5);
                    padding-bottom: space();
                }
            }
        }

        h2 {
            border-bottom: 1px solid color(primary);
            font-size: 2em;
            padding: space(.5);
            text-align: center;
            transition: all .5s ease;

            @include mdlg {
                border: 0;
            }
        }

        img {
            display: block;
            margin: auto;
        }

        .r-link {
            @include mdlg {
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .img-responsive {
            margin: 0;
        }
    }
}

// Affichage label offre 
.offer_label {
    color: #fff;
    font-size: 0.7rem;
    left: 50%;
    padding: 6px;
    position: absolute;
    text-align: center;
    top: 0;
    transform: translate(-50%, -50%);
    width: 80%;

    @include mdlg {
        font-size: 0.85rem;
        top: -36px;
    }

    &.offer_label_primary,
    &.offer_label_primary_premium_secondary {
        background-color: color(primary);
    }

    &.offer_label_secondary {
        background-color: color(secondary);
    }
}

// Affichage du prix
.price {
    margin: auto 0 space(-1);
    text-align: center;
    transition: all .5s ease;

    strong,
    span {
        display: inline-block;
    }

    .price_wrap {
        display: inline-block;
    }

    span {
        display: block;

        +.price_wrap {
            display: block;
        }
    }

    strong {
        padding: 7px 0;

        span {
            display: inline;
            vertical-align: top;
        }
    }
}

.price_diminue {
    color: #f00;
    display: inline-block;
    margin: 10px 2px;
}

// .price_barre {
//     display: inline-block;
//     font-size: 2rem;
//     line-height: 50px;
//     margin: 0 2px;
//     position: relative;

//     &:before {
//         -moz-transform: rotate(-20deg);
//         -o-transform: rotate(-20deg);
//         -webkit-transform: rotate(-20deg);
//         background: #f00;
//         content: '';
//         display: block;
//         height: 2px;
//         position: absolute;
//         top: 50%;
//         transform: rotate(-20deg);
//         width: 100%;
//     }
// }

.pane-title {
    display: none;
}

.description {
    margin: 10px;
    text-transform: uppercase;

    strong {
        font-family: $font-family-base;
        font-size: 1em;
        font-weight: bold;
        text-transform: capitalize;
    }
}

.description-desktop {
    display: none;

    @include mdlg {
        display: block;
    }
}

.description-mobile {
    display: block;

    @include mdlg {
        display: none;
    }
}
