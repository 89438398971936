//
// Link
//

.r-link {
    &.r--block {
        display: block;
    }

    &.r--surface {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: z-index();
    }
}
