//
// Layout > Footer
//

r-footer,
r-footer--links {
    display: block;
}

// Custom Drupal

// r-footer {
//     r-menu {
//         &.r--main-menu {
//             background: none;
//             text-align: right;

//             r-menu--item {
//                 display: inline;
//             }

//             .r-menu--link {
//                 color: color(primary);
//                 display: inline;
//                 font-family: $font-family-base;
//                 font-size: font-size(small);
//                 font-weight: normal;
//                 padding: space(.5);
//                 text-decoration: underline;

//                 &:hover,
//                 &:focus {
//                     background: none;
//                     text-decoration: none;
//                 }
//             }
//         }
//     }

//     r-menu--item {
//         border: none;
//     }
// }
