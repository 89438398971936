//global
.node-type-kiosque {
    background: color(gray, lighter);

    .main-container {
        @include lg {
            max-width: $html-max-width;
        }
    }

    article {
        margin-top: 30px;
    }

    form {
        @include clearfix;
    }

    .cm-main-title {
        h1 {
            margin-bottom: 30px;
            text-align: center;

            @include xssm {
                margin-bottom: 15px;
            }
        }
    }

    .cm-form-content {
        min-width: 0;
        width: auto;

        .panel-body {
            padding-top: 0;
        }
    }

    .onglets-paiement {
        #tab-payment-PAYU,
        #tab-payment-PAYR {
            input[type='image'] {
                float: left;
            }

            .montant-tab {
                clear: both;
            }
        }

        .active {
            background-color: transparent;
        }
    }

    .field-name-field-visuel-grand {
        position: relative;
        right: 0;
        top: 0;
        transition: all .5s ease;
        width: 85%;
        z-index: 2;

        .img-responsive {
            height: auto;
            transition: all .5s ease;
            width: 90%;
        }
    }

    .field-name-field-prix-affiche {
        background: color(secondary);
        border-radius: 50%;
        color: color(primary);
        font-family: $font-family-title;
        font-size: 6vh;
        font-weight: bold;
        height: 100px;
        line-height: 100px;
        margin: 30px auto;
        position: absolute;
        right: 6%;
        text-align: center;
        top: -70px;
        transition: all .5s ease;
        z-index: 3;
        width: 100px;
    }

    .webform-submit[value='hidden'] {
        display: none;
    }
}

.cm-kiosque-une {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &:hover {
        .img-responsive {
            box-shadow: 0 0 1px color(gray, light);
            transform: perspective(600px) rotateY(0deg);
        }

        .field-name-field-visuel-grand {
            right: 5%;
        }
    }

    .field-name-field-descriptif-long {
        margin: 70px auto 0;
    }
}

.cm-kiosque-supplements {
    position: absolute;
    right: 13%;
    top: 11vh;
    transition: all .5s ease;
    width: 11%;
    z-index: 1;

    h3 {
        font-size: $font-size-base;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 15px;
    }

    img {
        width: 100%;
    }
}

//partie gauche
.cm-stand {
    ul {
        background: linear-gradient(180deg, color(gray, lighter) 80%, color(gray, lighter) 90%, #ddd 100%);
        border-bottom: 3px solid #999;
        list-style: none;
        margin: 0;
        padding: 30px 15px 0;
    }

    li {
        display: inline-block;
        margin: 10px 0 0;
        text-align: center;
        vertical-align: bottom;
        width: 24%;
    }

    img {
        box-shadow: 3px -1px 3px #aaa;
        width: 90%;
    }
}

.cm-kiosque-form {
    background: color(light);
    box-shadow: 0 0 1px #ccc;

    .cm-progressbar {
        .cm-progressbar-page {
            padding: 15px;
        }

        &:first-child {
            .cm-progressbar-page {
                &.current {
                    background: none;
                    border-top: 0;
                    color: color(gray, dark);
                    font-family: $font-family-title;
                    font-size: 4rem;
                    text-align: left;
                }
            }
        }
    }
}

@keyframes cmune {
    0% {
        box-shadow: 0 0 1px color(gray, light);
        transform: rotateY(0deg);
    }
}
