//
// Buttons
//

// Base styles
//

.btn,
.r-btn {
    display: inline-block;
    margin-bottom: space();
    &:last-of-type {
        margin-bottom: 0;
    }

    @include smmdlg {
        margin-bottom: 0;
    }

    + .r-btn {
        &:not(.r--block) {
            margin-top: space(.5);

            @include smmdlg {
                margin-left: space(.5);
            }
        }
    }

    // Buttons modifiers
    //

    &.r--facebook {
        margin-bottom: space(2);
        margin-top: space(2);
    }
}

.btn {
    border: 0;
    outline: none;
    width: auto;

    &:hover {
        color: color(light);
    }
}
