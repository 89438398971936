//
// Block
//

r-list {
    display: block;
}

r-list--item {
    display: block;
}

r-p {
    display: block;
}

.r-hidden,
.hidden {
    display: none;
}

// Block > Expand

r-box {
    &.r--section {
        color: color(primary);

        r-box--body {
            margin: auto;
            max-width: 970px;
            padding: space();

            @include smmdlg {
                padding: space(2) space();
            }
        }

        h3 {
            font-size: font-size(h4);

            @include smmdlg {
                font-size: font-size(h3);
            }
        }
    }

    &.r--white {
        background: color(light);
    }
    &.r--gray {
        background: color(gray, lighter);
    }

    &.r--promo {
        display: none;

        @include smmdlg {
            display: block;
        }

        a {
            display: block;
        }
    }

    &.r--block {
        padding: space(2) 0;
        border-bottom: 1px solid color(gray, light);

        h2 {
            color: color(primary);
        }

        r-box--header {
            padding: 0 10px;
            margin-bottom: space();
        }

        r-box--body {
            padding: 0 10px;
            text-align: left;

            &.-has-columns {
                .r-btn {
                    margin-top: 0;
                }
            }
        }

        .r-btn {
            margin-top: space();
        }
    }

    &.r--expand {
        margin-bottom: space();

        @include smmdlg {
            margin-bottom: 0;
        }

        r-box--header {
            position: relative;
        }

        r-box--body {
            max-height: 0;
            overflow: hidden;
            transition: max-height .2s linear;

            @include smmdlg {
                max-height: none;
                overflow: visible;
            }
        }

        &.-is-open {
            r-box--body {
                max-height: 500px;
            }
        }
    }
}

r-box--expand {
    @include smmdlg {
        display: none;
    }
}

//Bloc détail offre formulaire
.r--details-offre-content {
    svg {
        fill: color(primary)
    }
    .r--details-offre-title {
        color: color(primary);
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 space() 0;
        text-align: left;
    }

    .accordion {
        color: color(gray, base);
        cursor: pointer;
        font-size: .8rem;
        margin-top: space();
        transition: 0.4s;

        @include smmdlg {
            margin-top: 0;
        }
    }

    .panelDetail {
        display: none;

        &.show {
            display: block;
        }
    }
}

.r--details-offre-price {
    span {
        background-color: color(primary);
        border-radius: 12px;
        color: #fff;
        display: inline-block;
        padding: space();

        @include smmdlg {
            float: right;
            margin: 0 auto 0 auto !important;
        }
    }
}
