div.krumo-root {
    color: color(dark) !important;

    a {
        color: color(dark) !important;
    }
}

.cm-messages {
    clear: both;
    margin: auto;
    max-height: 50vh;
    max-width: $html-max-width;
    overflow: auto;

    .messages {
        margin: 20px auto 0;
    }
}

.nav-tabs {
    display: flex;
    list-style: none;
    background: color(light);

    li {
        padding: space(.5);
    }
}
