.compte-a-rebours_container {
    background-color: color(primary);
    border-radius: 24px;
    padding: 12px;

    h2 {
        color: #fff;
    }

    .btn {
        background-color: color(secondary);
        border-radius: 12px;
        color: color(light);
        font-size: 15px;
        margin-top: 12px;
        &:hover {
            text-decoration: none;
        }
    }

    .box-rebours {
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .box_jour, .box_heure, .box_minute, .box_seconde {
        background-color: #fff;
        border-radius: 12px;
        line-height: 1;
        margin-right: 12px;
        padding: 6px;
        width: 70px;
    }

    .box_seconde {
        margin-right: 0;
    }

    #jour, #heure, #minute, #seconde {
        align-items: center;
        color: color(primary);
        display: flex;
        font-size: 2rem;
        font-weight: bold;
        justify-content: center;
    }
    #jour {
        border-left: none;
    }

    #jour_label, #heure_label, #minute_label, #seconde_label {
        font-size: 0.8rem;
    }

    .box-rebours {
        margin-top: 12px;
    }

    @include smmdlg {
        .box-rebours {
            margin-top: 0;
        }
        .container_content_popin .btn {
          margin-top: 0
        }
    }
}
