.row {
    &.top {
        margin: 0;
    }
}

.middle {
    margin: 0 auto;
    max-width: $html-max-width;
}

.middle-top {
    display: flex;
    margin: 55px auto 0;
    text-align: center;

    @include xssm {
        flex-direction: column;
    }

    .panel-pane {
        margin: 0 auto 10px;
        text-align: left;
        width: 100%;

        @include xssm {
            margin-bottom: 30px;
            padding: 0;
        }

        @include mdlg {
            margin-bottom: 0;
        }

        &.pane-encarts-promotionnels-panel-pane-1 {
            order: 3;
            width: 25%;

            @include smmdlg {
                order: 1;
            }

            &.-is-top {
                order: 1;
            }

            @include mdlg {
                padding: 0 10px;
            }
        }

        &.pane-taxonomy-term-reordered-panel-pane-2 {
            order: 2;

            @include mdlg {
                padding: 0;
                width: 75%;
            }
        }
    }
}

.middle-bottom {
    margin-bottom: 30px;
    padding: 0;

    @include xssm {
        padding: 0;
    }
}

.bottom {
    margin: 0;
}

.bootstrap-onecol-stacked {
    background: color(gray, lighter);
}

.main-container {
	margin-top: $logo-height;
    max-width: 1980px;
    width: 100%;

    @include xssm {
        max-width: 100%;
    }

	@include mdlg {
        margin-top: 0;
    }

    .node-type-formulaire-perso &,
    .node-type-webform & {
        max-width: $html-max-width;
    }
}

/* GRILLE */
.container-row {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 32px 12px;
	position: relative;
}
.container-row-fluid {
    max-width: 100%;
}
.row-col {
	margin-left: -12px;
	margin-right: -12px;
	display: flex;
    flex-wrap: wrap;
}
.col-s-1, .col-s-2, .col-s-3, .col-s-4, .col-s-5, .col-s-6, .col-s-7, .col-s-8, .col-s-9, .col-s-10, .col-s-11, .col-s-12, 
.col-m-1, .col-m-2, .col-m-3, .col-m-4, .col-m-5, .col-m-6, .col-m-7, .col-m-8, .col-m-9, .col-m-10, .col-m-11, .col-m-12, 
.col-l-1, .col-l-2, .col-l-3, .col-l-4, .col-l-5, .col-l-6, .col-l-7, .col-l-8, .col-l-9, .col-l-10, .col-l-11, .col-l-12 {
	position: relative;
    min-height: 1px;
    padding-left: 12px;
    padding-right: 12px;
}
/* COLONNES MOBILE */
.col-s-1 {
	width: calc(100% / 12 * 1);
}
.col-s-2 {
	width: calc(100% / 12 * 2);
}
.col-s-3 {
	width: calc(100% / 12 * 3);
}
.col-s-4 {
	width: calc(100% / 12 * 4);
}
.col-s-5 {
	width: calc(100% / 12 * 5);
}
.col-s-6 {
	width: calc(100% / 12 * 6);
}
.col-s-7 {
	width: calc(100% / 12 * 7);
}
.col-s-8 {
	width: calc(100% / 12 * 8);
}
.col-s-9 {
	width: calc(100% / 12 * 9);
}
.col-s-10 {
	width: calc(100% / 12 * 10);
}
.col-s-11 {
	width: calc(100% / 12 * 11);
}
.col-s-12 {
	width: 100%;
}

/* COLONNES TABLETTE */
@include smmd{
    .col-m-1 {
		width: calc(100% / 12 * 1);
	}
	.col-m-2 {
		width: calc(100% / 12 * 2);
	}
	.col-m-3 {
		width: calc(100% / 12 * 3);
	}
	.col-m-4 {
		width: calc(100% / 12 * 4);
	}
	.col-m-5 {
		width: calc(100% / 12 * 5);
	}
	.col-m-6 {
		width: calc(100% / 12 * 6);
	}
	.col-m-7 {
		width: calc(100% / 12 * 7);
	}
	.col-m-8 {
		width: calc(100% / 12 * 8);
	}
	.col-m-9 {
		width: calc(100% / 12 * 9);
	}
	.col-m-10 {
		width: calc(100% / 12 * 10);
	}
	.col-m-11 {
		width: calc(100% / 12 * 11);;
	}
	.col-m-12 {
		width: 100%;
	}
}

/* COLONNES DESKTOP */
@include lg{
    .col-l-1 {
		width: calc(100% / 12 * 1);
	}
	.col-l-2 {
		width: calc(100% / 12 * 2);
	}
	.col-l-3 {
		width: calc(100% / 12 * 3);
	}
	.col-l-4 {
		width: calc(100% / 12 * 4);
	}
	.col-l-5 {
		width: calc(100% / 12 * 5);
	}
	.col-l-6 {
		width: calc(100% / 12 * 6);
	}
	.col-l-7 {
		width: calc(100% / 12 * 7);
	}
	.col-l-8 {
		width: calc(100% / 12 * 8);
	}
	.col-l-9 {
		width: calc(100% / 12 * 9);
	}
	.col-l-10 {
		width: calc(100% / 12 * 10);
	}
	.col-l-11 {
		width: calc(100% / 12 * 11);
	}
	.col-l-12 {
		width: 100%;
	}
}