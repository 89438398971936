.glide__track {
  border: 2px solid color(gray, light);
  border-radius: space();
  padding-bottom: space(1.5);

  h3 {
    text-align: center;
  }

  .r-btn {
    &.r--primary {
      margin:0 auto 1rem;
    }
  }
}

.glide__slide {
  @include xssm {
    padding: 0 space();
  }

  .row-col {
    @include mdlg {
      padding: space(1.5) space(2) 0;
    }

    @include xssm {
      padding: space(2) 0 0;
    }

    @include xssm {
      h1, h2, h3, h4, h5, h6 {
        br {display: none;}
      }

      iframe {
        width: 100%;
      }
    }
  }
}

.glide__arrow {
  &.glide__arrow--left, 
  &.glide__arrow--right {
    align-items: center;
    background-color: color(gray, lighter);
    border: 1px solid color(primary);
    border-radius: 50%;
    color: color(primary);
    cursor: pointer;
    display: flex;
    font-size: 32px;
    justify-content: center;
    height: 40px;
    position: absolute;
    text-align: center;    
    width: 40px;

    @include xssm {
      top: 0;
      transform: translateY(-50%);
    }

    @include mdlg {
      top: 50%;
    }

    &:before {
      display: inline-block;
      font-family: main;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      line-height: 1;
      text-transform: none;
    }

    &.disabled {
      border: 2px solid color(gray, light);

      &:before {
        opacity: .25;
        color: color(gray, light);
        cursor: default;
      }
    }
  }

  &.glide__arrow--left {
    @include mdlg {left: -20px;}
    @include xssm {left: 40%;}

    &:before {content: $icon-arrow-left}
  }
  &.glide__arrow--right {
    @include mdlg {right:-20px;}
    @include xssm {right: 40%;}
    
    &:before {content: $icon-arrow-right}
  }
}

.glide__bullets {
  position: relative;
  text-align: center;

  .glide__bullet {
    background-color: color(gray, lighter);
    border: 1px solid color(gray, light);
    border-radius: 50%;
    height: 14px;
    width: 14px;

    &.glide__bullet--active {
      background-color: color(primary);
      border: 1px solid color(primary);
    }
  }
}


