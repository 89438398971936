.cm-thank {

  .gr-message {
    background: color(gray, lighter);
    padding: 20px 40px 0;
  }

  .gr-message {
    padding: 20px 40px 30px;
    color: color(gray, darker);
    font-family: $font-family-alt;
    font-size: 18px;

    h2 {
      font-family: $font-family-alt;
      margin-bottom: space();
      color: color(primary);
    }

    p {
      margin-bottom: 5px;
    }

    strong {
      font-weight: bold;
      color: color(primary);
    }
  }

  .cm-thank-btn {
    background: color(gray, lighter);
    padding: 10px;
    position: relative;
    z-index: 10;

    .btn {
      background: color(primary);
      color: color(light);
      text-transform: none;
      border-radius: 5px;
      font-family: $font-family-alt;
      font-size: 21px;

      &:hover,
      &:focus {
        color: color(primary);
      }
    }
  }

  .cm-sepa-btn {
    background-color: color(primary);
    border-radius:5px;
    height: 50px;
    padding:8px 30px;
    text-decoration: none;
    width: auto;

    span {
        color: #fff;
        font-size:16px;
        font-style:normal;
        font-weight: normal;
    }
  }
}
