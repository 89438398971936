.page-unauthorized {
    color: color(primary);

    p:first-child {
        font-weight: bold;
    }

    .btn {
        color: primary !important;
    }
}
