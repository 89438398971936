@keyframes scaleUp {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.cm-messages {
    position: fixed;
    left: auto;
    bottom: 20px;
    right: 20px;
    width: calc(100% - 40px);
    max-width: 500px;
    margin: 0;
    z-index: 1000;
    overflow: visible;
    display: flex;
    flex-direction: column-reverse;

    .close {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        line-height: 18px;
        color: color(light);
        opacity: 1;
        border-radius: 50%;
        font-weight: 300;
        text-align: center;
        font-size: 16px;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    .messages {
        animation: scaleUp 0.4s 1s ease-in-out forwards;
        background: color(light);
        border-radius: 3px;
        box-shadow: 0 0 10px color(dark, .2);
        margin: 0 0 10px;
        opacity: 0;
        overflow: visible;
        transform: translateY(40px);
        transition: all linear 0.2s;

        a {
            color: color(primary);
            text-decoration: underline;
        }

        &.warning,
        &.status,
        &.error {
            color: color(dark);
            position: relative;
            padding-left: 50px;

            &:before {
                font-family: main;
                display: inline-block;
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 25px;
            }
        }

        &.status {
            border-left: 4px solid #61c7d4;

            &:before {
                content:$icon-notif-info;
                color:#61c7d4;
            }

            .close {
                background: #61c7d4;

                &:hover,
                &:focus {
                    background: darken(#61c7d4, 5%);
                }
            }
        }

        &.warning {
            border-left: 4px solid #efd08f;

            &:before {
                content:$icon-warning;
                color: #efd08f;
            }

            .close {
                background: #efd08f;

                &:hover,
                &:focus {
                    background: darken(#efd08f, 5%);
                }
            }
        }

        &.error {
            border-left: 4px solid #e48281;

            &:before {
                content:$icon-error;
                color: #e48281;
            }

            .close {
                background: #e48281;

                &:hover,
                &:focus {
                    background: darken(#e48281, 5%);
                }
            }
        }
    }
}


@for $i from 1 through 10 {
    .cm-messages .messages:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.2}s;
    }
}


.message-alerte {
    color: #fff;
    background: red;
    padding: 10px;
}
