//
// Modal
//

// Modal > Modal Content
//

r-modal--content {
  r-box {
    background-color: color(light);
    border-radius: 2px;
    box-shadow: $modal-box-shadow;
  }

  r-box--header,
  r-box--body,
  r-box--footer {
    padding: space(1.5) space(3);
    text-align: center;

    r-p {
      display: block;
      // font-family: $font-family-alt
    }
  }

  r-box--header {
    @include font(h2);
    background-color: color(gray, lighter);
    color: color(primary);
    font-weight: font-weight(bold);
    line-height: 1;
  }

  r-box--body {
    r-p {
      color: color(gray);
      display: block;

      &.r--sub-title {
        color: color(primary);
        font-size: font-size(h4);
        margin-bottom: space();
        margin-top: space();
      }
    }

    .r-btn {
      display: block;
      margin-left: auto;
      margin-right: auto;

      @include smmdlg {
        display: inline-block;
        margin-left: space();
        margin-right: space();
      }
    }
  }
}
