.r-novacity {
  $content-max-width: 1328px;
  $arche-width: 996px;

  .cm-progressbar {
    display: none;
  }

  .r-btn--back {
    align-items: center;
    border: 1px solid color(dark);
    border-radius: 5px;
    color: color(dark);
    display: flex;
    font-weight: bold;
    margin-bottom: space(1.5);
    max-width: fit-content;
    padding: 4px space(0.75);
    text-decoration: none;

    @include xs {
      font-size: 1.2rem;
    }

    @include smmdlg {
      margin-right: space();
    }

    &:hover {
      color: color(dark);
    }

    r-icon {
      margin-right: space(0.75);
      transform: rotate(180deg);
    }
  }

  h2 {
    color: color(dark);
    font-size: 1.8rem;
    font-weight: 700;
  }

  h3 {
    color: color(gray);
    font-family: $font-family-base;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 0 space(2) space(2);
  }

  label {
    color: color(dark);
    font-weight: 700;
  }

  .form-required {
    color: color(dark);
  }

  .r-form--input-text {
    background: #f1f1f1;
    border: none;
    border-radius: 8px;
  }

  .form-submit {
    background: color(primary);
    border-radius: 4px;
    color: color(light);
    font-weight: normal;
    margin: 0 auto;

    &:hover {
      background: color(primary);
      opacity: 0.8;
    }
  }

  // Footer
  r-footer {
    r-box--body {
      border-top: 1px solid color(primary);
      color: color(primary);
      display: grid;
      grid-template-areas: ". . . .";
      grid-column-gap: space(2.5);
      grid-template-columns: 2fr 1fr 2fr 1fr;
      padding: space() space() 60px;
      text-align: left;

      @include lg {
        padding: space(2)
          maxi(calc(7.8vw + #{space()}), calc(50vw - #{$content-max-width / 2}))
          60px;
      }

      @include md {
        padding: space(2) maxi(space(4), calc(50vw - #{450px + space(2.5)}))
          60px;
      }

      @include wallpaper {
        background: color(light);
        grid-template-columns: repeat(4, 1fr);
        margin: auto;
        padding: space();
        width: $arche-width;
      }
    }

    a,
    .r-footer--link {
      color: color(gray);
    }
  }

  r-footer--section {
    display: block;
    grid-column-start: 1;
    grid-column-end: -1;
    text-transform: uppercase;

    &:nth-of-type(odd) {
      r-footer--list {
        @include mdlg {
          columns: 2;
        }

        @include wallpaper {
          columns: 1;
        }
      }
    }

    @include sm {
      padding-left: space(2);
    }

    @include smmdlg {
      display: block;
      grid-column: auto;
      margin-top: space(2);
    }
  }

  r-footer--list {
    display: block;

    @include xs {
      display: none;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s linear;
    }

    @include mdlg {
      columns: 2;
    }

    @include wallpaper {
      columns: 1;
    }
  }

  .r-footer--title {
    font-family: $font-family-base;
    font-size: font-size(h6);
    font-weight: normal;
    margin-bottom: space(0.5);

    @include xs {
      align-items: center;
      display: flex;
      height: 48px;
      margin-bottom: space(0);
      margin-top: space(0);
      padding-bottom: space(0);
      position: relative;

      &:after {
        content: $icon-arrow-right;
        font-family: main;
        position: absolute;
        right: 20px;
        transition: all 0.25s ease 0s;
      }
    }

    @include mdlg {
      padding-bottom: space();
    }
  }

  .r-footer--link {
    background: none;
    display: block;
    margin: 0 space(0.5) space(0.5) 0;
    padding-left: 0;
    text-align: left;
    text-transform: uppercase;
  }

  .r-footer--social {
    align-items: center;
    border-bottom: 1px solid color(gray, light);
    color: color(gray);
    grid-column: 1 / span 4;
    margin: space(2) 0 space();
    padding: space();
    text-align: center;

    @include smmdlg {
      display: flex;
      justify-content: center;
    }

    a {
      align-items: center;
      display: inline-flex;
      padding: space(0.5) space();

      &:hover {
        color: color(primary);
        text-decoration: none;
      }
    }

    r-icon {
      font-size: 1rem;
      margin-right: space(0.5);
    }
  }

  .r-footer--logo {
    column-gap: space(0.5);
    display: flex;
    grid-column: 1 / span 2;

    @include smmdlg {
      column-gap: space();
      grid-column: 1;
    }

    svg {
      max-width: 150px;
    }

    .logo-footer-white {
      display: none;
    }
  }

  .r-footer--newsletter {
    @include xs {
      display: none;
    }

    @include mdlg {
      align-items: center;
      display: flex;
      grid-column: span 2;
    }

    .r-btn {
      border-radius: 5px;
      color: color(light);
      font-weight: normal;
      font-size: 0.9rem;
      padding: space(0.5) space(0.75);

      @include sm {
        margin-top: space(0.5);
      }

      @include mdlg {
        margin-left: space(0.5);
      }
    }

    r-icon {
      font-size: 34px;
      margin-left: space();
    }
  }

  .r-footer--top {
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 0.9rem;
    grid-column: 4;
    justify-self: right;

    &:hover {
      color: color(primary, light);
      text-decoration: none;
    }

    r-icon {
      font-size: 2rem;
      margin-left: space();

      @include smmdlg {
        font-size: 2.7rem;
      }
    }

    span {
      @include xs {
        display: none;
      }
    }
  }

  .r-footer--services {
    @include xs {
      display: block;
      grid-column-start: 1;
      grid-column-end: -1;
      margin: 0 0;

      r-footer--list {
        columns: 2;
      }
    }
  }

  .r-footer--copyright {
    color: color(primary);
    grid-column: 1 / span 4;
    max-width: 100%;

    @include xs {
      border-top: 1px solid color(light);
      color: color(primary);
      font-size: font-size(small);
      margin-top: space(0.5);
      padding-top: space(0.5);
    }
  }

  .r-footer--source-sure {
    float: left;
    height: 40px;
    margin-right: space();
  }

  .r-footer--cim {
    float: right;
    height: 60px;
    margin-left: space();
  }
}
