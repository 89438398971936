.inscription_aux_newsletters {
    background: color(light);
    font-family: $font-family-base;
    font-size: 18px;
    color: color(gray, dark);
    overflow: hidden;
    border: 1px solid color(gray, light);

    #wrapper {
      max-width: 90%;
      margin: 5% auto;
      padding: 20px 40px 0;
      position: relative;
      z-index: 10;

      strong {
        color: color(primary);
      }

      // &:after {
      //   content: " ";
      //     position: absolute;
      //     z-index: -1;
      //     bottom: 0px;
      //     left: 15%;
      //     width: 70%;
      //     height: 50px;
      //     border-radius: 125px / 12px;
      //     box-shadow: 0 0 40px color(gray);
      // }
    }

    h1 {
      margin: 5px 0 15px;
      font-family: $font-family-base;
      color: color(primary);
      font-size: 30px;
      text-align: left;

      &:after {
        margin-left: 10px;
        font-family: main;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          text-decoration: none;
          text-transform: none;
        content: $icon-newsletter;
        font-size: font-size(base);
      }
    }

    .intros {
      strong {
        display: block;
        margin-top: 20px;
        font-family: $font-family-base;
        font-weight: bold;
      }
    }

    .form-list {
      h2 {
        display: none;
      }
    }

    form {
      position: relative;
      z-index: 5;

      ul {
        padding: 0;
        margin: 0 0 space();
        display: block;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;

        @include smmdlg {
            display: flex;
        }

        li {
          display: block;
          padding: 15px 20px;
          list-style: none;
          background: color(light);
          border: 1px solid rgba(color(primary), .4);
          flex: 0 0 calc(50% - 20px);
          margin: 10px 10px 15px;
          overflow: hidden;


          &.news-ls_nl_ena,
          &.news-ls_nl_une {
            display: none;
          }

          @include smmdlg {
            align-items: flex-start;
            display: flex;
            margin-bottom: 10px;
          }

          .boutAjout {
            font-size: 14px;
            text-align: right;
            padding-right: 30px;
            position: relative;
            color: color(primary);
            font-weight: 700;
            margin-top: space();

            @include smmdlg {
                margin-top: 0;
            }

            &:before {
              content:"";
              display: inline-block;
              width: 20px;
              height: 20px;
              background: #F1F9FF;
              border: 2px solid color(primary);
              border-radius: 5px;
              vertical-align: middle;
              position: absolute;
              right: 0;
            }
          }

          &.active,
          &.selected {
            .boutAjout {

              &:before {
                background: color(primary);
              }

              &:after {
                font-family: main;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                  font-style: normal;
                  font-variant: normal;
                  font-weight: normal;
                  text-decoration: none;
                  text-transform: none;
                content: $icon-check;
                color: color(light);
                position: absolute;
                right: 0;
                top: 0;
                width: 20px;
                text-align: center;
                font-size: 8px;
                line-height: 22px;
              }
            }
          }
        }
      }

      .image {
        float: left;
        margin-bottom: 15px;
        margin-right: 15px;
        width: 100%;

        @include smmdlg {
            width: 38%;
        }

        img {
          max-width: 100%;
        }
      }

      .blocNews {
        cursor: pointer;
      }

      .text {
          float: left;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;

        @include smmdlg {
            // width: calc(62% - 15px);
            height: 100%;
        }

        input {
          display: none;
        }
      }
    }

    .TitreNews {
      color: color(primary);
      font-size: 21px;

      a {
        display: none;
      }
    }

    .TexteNews {
      font-size: 14px;
      margin-top: 0;
      flex: 1 1 auto;
    }

    #person-info {
      padding: space(2) 0 0;

      .email {
        text-align: left;
        margin-bottom: space(2);
        display: none;

        strong {
          display: none;
        }
      }
    }

    button {
      background: color(primary);
      border-radius: 5px;
      color: color(light);
      display: block;
      font-family: $font-family-alt;
      font-size: 21px;
      margin-right: auto;
      margin-left: auto;
      text-transform: none;
      padding: 0 space();
      margin-top: space(2);

      &:hover,
      &:focus {
        color: color(light);
        background: color(green);
        cursor: pointer;
      }

      &:disabled {
        color: color(gray, lighter);
        background: color(gray, light);

        &:hover {
          cursor: auto;
          color: color(gray, lighter);
          background: color(gray, light);
        }
      }
    }

    .checkbox {
      margin-left: 0;
      display: none; // > Ticket #138752

      input {
        display: none;

        &:checked + label {
          &:before {
            background: color(primary);
          }

          &:after {
            font-family: main;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
              font-style: normal;
              font-variant: normal;
              font-weight: normal;
              text-decoration: none;
              text-transform: none;
            content: $icon-check;
            color: color(light);
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            text-align: center;
            font-size: 8px;
            line-height: 22px;
          }
        }
      }

      label {
        color: color(gray, darker);
        position: relative;
        padding-left: 30px;

        &:before {
          content:"";
          display: inline-block;
          width: 20px;
          height: 20px;
          background: color(gray, lighter);
          border: 2px solid color(primary);
          border-radius: 5px;
          vertical-align: middle;
          position: absolute;
          left: 0;
        }
      }
    }
  }
