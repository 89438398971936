//
// Layout > Main
//

// Sticky footer
//

html,
body,
r-wrapper {
    min-height: 100vh;

    r-footer {
        margin-top: auto;
    }
}

// Main
//

r-wrapper {
    display: flex;
    flex-direction: column;
}

r-main {
    display: block;
}

r-main--content {
    width: 100%;

    &.r--creation {
        margin: auto;
        max-width: 700px;
    }
}

section {
    &.r--center {
        text-align: center;
    }

    &.r--vertical {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}

.cm-form-left {
    padding: space(2);
}
