.nav-tabs {
  border-bottom: 1px solid #ddd;
  padding: 0;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    display: block;
    line-height: 1.42857;
    margin-right: 2px;
}
.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #ddd;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
//   color: #555555;
//   background-color: #fff;
//   border: 1px solid #ddd;
//   border-bottom-color: transparent;
//   cursor: default;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.onglets-paiement {
  .panel-heading {
    color: color(dark) !important;

    a {
      color: color(dark) !important;
      line-height: 31px;

      &:hover {
        border: 0;
        color: color(primary) !important;
      }
    }

    .nav-tabs {
      background: color(light);
    }

    .active {
      background-color: color(gray, light);
    }

    fieldset.panel-default & {
      span {
        font-size: inherit;
      }
    }
  }

  .panel {
    border-radius: 0;
    box-shadow: none;
  }

  .tab-content {

    .choix-onglet {

      & + #tab-payment-PZR,
      & + #tab-payment-PZU,
      & + #tab-payment-PZPPR,
      & + #tab-payment-PZPPU,
      & + #tab-payment-PZUOC,
      & + #tab-payment-PZROC {
        display: none;
        height: 0;
        overflow: hidden;
        transition: height .3s;
      }

      &.is-open {
        & + #tab-payment-PZR,
        & + #tab-payment-PZU,
        & + #tab-payment-PZPPR,
        & + #tab-payment-PZPPU,
        & + #tab-payment-PZUOC,
        & + #tab-payment-PZROC {
          display: block !important;
          height: auto !important;
          overflow: hidden !important;
        } 
      }
    }
  }
}

#edit-submitted-pay-fieldset-paiement {
  .panel-heading {
    display: none;
  }

  > .panel-body {
    border: 0;
    padding: 0;
  }

  .with-nav-tabs {
    border: 0;
    box-shadow: none;

    .panel-heading {
      padding: 0;
    }

    .panel-body {
      border-top: 0;
      padding: 15px;
    }
  }
}

.paiement {
  h1 {
    font-size: 27px;
    margin-top: 0;
  }

  .btn-success {
    color: color(dark);
    font-size: 21px;
    font-weight: bold;
    padding: 5px 10px;
  }
}

//onglet
.tab-payment-FREE {
  //display: none;
}

.tab-payment-OGU,
.tab-payment-OGR,
.tab-payment-OGR_FREE
 {
  background-image: url("/sites/all/themes/custom/boutique/bootstrapMarketing/img/credit_card.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  width: 100px;

  a {
    text-indent: -999em;
  }

  .control-label {
    display: block;
  }
}

.tab-payment-PPU,
.tab-payment-PPR {
  background-image: url("/sites/all/themes/custom/boutique/bootstrapMarketing/img/paypal.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  width: 115px;

  a {
    text-indent: -999em;
  }
}

.tab-payment-PPU,
#tab-payment-PPU,
.tab-payment-PPR,
#tab-payment-PPR {
  button {
    background: none;
    padding: 0;
    // &.btn {
    //   background-color: color(button);
    //   color: color(primary);
    //   padding: space(.75) space(1.5);
    // }
  }
}

.tab-payment-TWK {
  h2 {
    font-size: 21px;
  }

  a {
    .onglets-paiement .panel-heading & {
      font-family: $font-family-alt;
      line-height: 30px;
    }
  }

  .tab-logo-TWK {
    background-image: url("/sites/all/themes/custom/boutique/bootstrapMarketing/img/twikey.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    width: 70px;
    min-height: 31px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 2px;

    fieldset.panel-default .onglets-paiement .panel-heading & {
      font-size: 0;
    }
  }
}

#tab-payment-OGR_FREE {
  .node-type-webform & {
    h2 {
      display: none;
    }
  }
}

.tab-payment-PAYU,
.tab-payment-PAYR {
  background: url("/sites/all/themes/custom/boutique/bootstrapMarketing/img/paypal.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  width: 90px;

  a {
    text-indent: -999em;
  }

  form {
    padding: 15px;
    @include clearfix;
  }
}

.tab-payment-SMS {
  background-image: url("/sites/all/themes/custom/boutique/bootstrapMarketing/img/sms.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  width: 60px;

  a {
    text-indent: -999em;
  }

  .texteAbon {
    padding: 0 15px 20px;
  }
}

//paiement form
#tab-payment-PAYU,
#tab-payment-PAYR {
  input[type="image"] {
    display: block;
    float: none;
    margin: 20px;
  }
}

#tab-payment-OGU,
#tab-payment-OGR,
#tab-payment-OGR_FREE
 {
  .form-group {
    display: block;
    margin-bottom: 5px;
  }
}

#edit-submitted-pay-fieldset-paiement-prix {
  font-family: $font-family-title;
  font-size: 21px;
  font-weight: bold;
  text-align: left;

  label {
    margin: 0;
    width: auto;
  }
}
