fieldset {
    border: none;
    margin: space(3) 0 0;
    max-width: 600px;
    padding: 0;

    legend {
        color: color(primary);
        font-size: font-size(h4);
    }

    r-form--group {
        margin-top: 0;
    }

    input {
        font-family: $font-family-base;
        font-size: font-size(base);
        max-width: 100%;
        padding: space(.5) space();
        width: 100%;

        &[type="checkbox"] {
            width: auto;
        }
    }

    &.collapsible {
        margin-bottom: space(3);

        legend {
            html.js & {
                .fieldset-legend {
                    background-color: color(button);
                    color: #fff;
                    padding: 8px 24px;
                    text-decoration: none;
                    transition: 0.3s;

                    // &::before {
                    //     color: #fff;
                    //     content: $icon-angle-arrow-down;
                    //     font-family: main;
                    //     font-size: font-size(small);
                    //     margin-right: space(.5);
                    //     position: relative;
                    //     top: -1px;
                    //     vertical-align: middle;
                    // }
                    &:hover {
                        background-color: lighten(color(button), 15%);
                    }
                }
            }

            a {
                color: color(primary);
                font-size: font-size(h5);
                text-decoration: underline;
            }
        }
    }

    &.collapsed {
        html.js & {
            height: auto;

            legend {
                .fieldset-legend {
                    // &::before {
                    //     content: $icon-angle-arrow-right;
                    // }
                }
            }
        }
    }
}

.zipcode_francais_codepostal_list_group {
    margin: 0;
    width: 100%;

    li {
        padding: 10px;
    }
}

.panel-collapse {
    display: none;

    &.in {
        display: block;
    }
}

.webform-container-inline {
    align-items: center;
    display: flex;

    @include xssm {
        flex-wrap: wrap;
        gap: space(.25);
    }

    label {
        margin-bottom: 0;
        text-wrap: nowrap;
        width: calc(100% / 3);

        @include xssm {
            width: 100%;
        }

        &.option {
            width: 100%;
            text-wrap: nowrap;
        }
    }

    r-form--group {
        display: flex;
        margin: 0 2px;
        justify-content: space-between;

        @include xssm {
            margin: 0;
        }

        .r--select {
            @include xssm {
                width: calc(100% / 3);
            }
        }
    }
}

.webform-component-belgian-address {
    float: left;
}

.webform-component--belgian-address--adresse-belge--numero-rue,
.webform-component--belgian-address--adresse-belge--boite {
    clear: left;
}

.webform-component--belgian-address--adresse-belge--code-postal,
.webform-component--belgian-address--adresse-belge--boite {
    width: 30%;
}

.webform-component--belgian-address--adresse-belge--ville,
.webform-component--belgian-address--adresse-belge--complement-adresse {
    width: 70%;
}

.webform-component--belgian-address--adresse-belge--numero-rue {
    width: 25%;
}

.webform-component--belgian-address--adresse-belge--nom-rue {
    width: 75%;
}

.webform-component--belgian-address--adresse-belge--code-postal,
.webform-component--belgian-address--adresse-belge--numero-rue,
.webform-component--belgian-address--adresse-belge--boite {
    padding-right: space();
}

.webform-datepicker,
.webform-container-inline .webform-datepicker {
    @include smmdlg {
        align-items: center;
        display: flex;
    }

    .r--select {
        @include smmdlg {
            flex: 0 0 25%;
            margin-bottom: 0;
            margin-right: space();
        }
    }

    .webform-calendar {
        html.js & {
            display: none;

            @include smmdlg {
                display: block;
            }
        }
    }
}

.r-form {
    padding: space(.75);

    @include smmdlg {
        padding: space(2);
    }
}

.form-actions {
    margin-top: space(2);

    .r-btn+.r-btn:not(.r--block) {
        margin-top: 0;
    }

    .formulaire-perso-upgrade-abonnement & {
        display: inline-block;
    }
}

// barre d'étapes
.cm-progressbar-page {
    border-top: 1px solid color(gray, lighter);
    color: color(gray, light);
    display: block;
    font-family: $font-family-alt;
    font-weight: bold;
    font-size: 2.5rem;
    padding: 5px 10%;

    @include smmdlg {
        font-size: 4.2rem;
        padding: space();
    }

    &.completed,
    &.current {
        display: none;
    }

    &.current {
        color: color(gray, dark);
    }
}

.cm-progressbar {
    margin: space() space(-.75) 0;

    @include smmdlg {
        margin: 0 space(-2) space(-2);
    }

    &:first-child {
        margin: 0 space(-.75) space();
        width: auto;

        @include smmdlg {
            margin: space(-2) space(-2) space(2);
        }

        .cm-progressbar-page {
            display: none;

            &.current {
                background: color(gray, lighter);
                border-top: 1px solid color(gray, lighter);
                color: color(primary);
                display: block;
                font-family: $font-family-alt;
                font-size: 2rem;

                @include smmdlg {
                    font-size: 3rem;
                }
            }
        }
    }
}

.cm-progressbar-number,
.cm-progressbar-page-number {
    display: none;
}

.r-form--input-checkbox {
    height: $custom-control-indicator-size;
    top: 2px;
    width: $custom-control-indicator-size;
}

.password-verification {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    li {
        align-items: center;
        border-right: 1px solid color(gray, light);
        color: color(gray, base);
        display: flex;
        font-size: 0.8em;
        margin-bottom: space(.5);
        padding: 0 space(.5);

        &:last-of-type {
            border-right: none;
        }
    }

    r-icon {
        color: color(gray, base);
        margin-right: space(.5);
    }

    .password-error {
        r-icon {
            color: color(error);
        }
    }

    .password-validate {
        r-icon {
            color: color(success);
        }
    }
}

// waiting overlay

#waiting_overlay {
    background-color: #fff;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 30;

    .content_wainting_overlay {
        align-items: center;
        display: flex;
        left: 50%;
        max-width: 800px;
        position: absolute;
        top: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }

    .waiting_gif {
        height: auto;
        margin-right: 12px;
        width: 30px;
    }

    .waiting_message {
        color: #000;
        font-size: 1rem;
        font-weight: bold;

        @include mdlg {
            font-size: 2.5rem;
        }
    }
}

// vise => formulaire contact
#edit-submitted-file-ajax-wrapper {
    .webform-container-inline {
        flex-wrap: wrap;

        @include xssm {
            flex-direction: column;
            align-items: start;
        }

        label {
            width: auto;
            margin-bottom: 0;
        }

        #edit-submitted-file-upload {
            width: auto;
        }

        r-form--text {
            width: 100%;
        }

        .form-control.form-file {
            width: auto;
        }

        .form-managed-file {
            @include xssm {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

//popover
.popover {
    background: color(light);
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
    padding: 1rem;
    max-width: 450px;

    .popover-title {
        display: none;
    }
}

///newZipCode

.new-zipcode {
    margin-bottom: space(.25);

    r-form--group {
        display: none;

        &.new-zipcode-input,
        &.new-zipcode-select {
            display: block;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .fieldset-wrapper,
    .panel-body {
        display: flex;
        //flex-direction: column;
        flex-wrap: wrap;

        @include smmdlg {
            flex-direction: row;

        }
    }

    input {
        border: 1px solid color(gray, light);
        border-radius: space(.25);
    }

    .new-zipcode-input {
        margin-bottom: 0;
        max-width: space(8);

        @include smmdlg {
            width: calc(30% - 1rem);
        }
    }

    .form-text {
        &:focus {
            background-color: color(light);
            border: 1px solid color(gray, light);
        }
    }

    .new-zipcode-select {
        margin-bottom: 0;
        margin-left: 0;


        @include smmdlg {
            margin-left: space();
            width: 70%;
        }

        select {
            align-items: center;
            border: 1px solid color(gray, light);
            border-radius: 4px;
            display: flex;
            //padding: 10px space();
            width: 100%;


            // &:hover,
            // &:checked,
            // &:active {
            //     background-color: color(light);
            // }
        }
    }

    .zipcode_autocomplete_ville {
        appearance: auto;
        padding-left: space();
        padding-right: space();
        height: 42px;

        &[disabled="disabled"] {
            appearance: none;
            background-color: color(gray, light);
            padding-left: space();
            padding-right: space();
            height: 42px;
        }

        &.locked {
            appearance: none;
            pointer-events: none;
            padding-left: space();
            padding-right: space();
            height: 42px;
        }
    }
}