fieldset.panel-default {
    // .panel-body {
    //     padding: 15px 5px 0;
    // }

    .panel-heading {
        text-align: center;
        width: 100%;
        margin-bottom: 0;;
        span {
            font-size: space(1.5);
            font-weight: 700;
        }
    }
}

// .form-group {
//     margin: 0;

//     label.control-label {
//         color: color(primary);
//         font-family: $font-family-base;
//         font-size: font-size(base);
//         line-height: 28px;
//         margin-top: 5px;
//         padding-left: 0;
//         position: static;
//     }

//     &.label-static,
//     &.label-floating.is-focused,
//     &.label-floating:not(.is-empty) {
//         label.control-label {
//             color: color(primary);
//             font-family: $font-family-base;
//             font-size: font-size(base);
//             line-height: 28px;
//             position: static;
//         }
//     }
// }

//bouton radio
// .radio {
//     margin: 0;

//     input[type='radio'] {
//         margin: 0 10px;
//         opacity: 1;
//         position: absolute;
//         top: 4px;
//     }
// }

//checkbox
// .checkbox {
//     margin: 0;

//     input[type='checkbox'] {
//         left: 0;
//         margin: 0 10px;
//         opacity: 1;
//         pointer-events: auto;
//         position: absolute;
//         z-index: 1;
//     }
// }

// .webform-client-form {
//     .webform-component-radios {
//         > .control-label {
//             color: color(primary);
//             font-family: $font-family-base;
//             font-size: font-size(h4);
//             line-height: 28px;
//             margin: 0;
//         }

//         .form-radios {
//             margin-left: 0;
//         }

//         .radio {
//             margin: 0;

//             .control-label {
//                 color: color(primary);
//                 font-family: $font-family-base;
//                 font-size: font-size(base);
//                 line-height: 22px;
//                 margin: 6px 0 0 15px;
//                 padding-left: $grid-gutter-width;
//                 position: relative;
//             }
//         }
//     }

//     .webform-component-checkboxes {
//         .checkbox {
//             margin: 0;
//             padding: 0;

//             .control-label {
//                 color: color(primary);
//                 font-family: $font-family-base;
//                 font-size: font-size(base);
//                 line-height: 22px;
//                 margin: 6px 0 0 15px;
//                 padding-left: $grid-gutter-width;
//                 position: relative;
//             }

//             .form-checkbox {
//                 margin-left: 0;
//                 top: 4px;
//             }
//         }

//         &.small {
//             .checkbox {
//                 .control-label {
//                     font-size: font-size(small);
//                 }
//             }
//         }
//     }
// }

// .form-checkboxes {
//     .checkbox {
//         .control-label {
//             margin-top: 5px;
//         }
//     }
// }

//file
// .webform-component-file {
//     .btn.btn-default {
//         background: color(button);
//         color: color(light);
//         font-size: 14px;

//         &::before {
//             content: '\E017';
//             font-family: icons;
//             margin-right: 7px;
//             font-size: 12px;
//             position: relative;
//             top: 1px;
//         }
//     }

//     .btn.btn-danger {
//         background: none;
//         color: color(primary);

//         &:hover {
//             background: none !important;
//             text-decoration: underline;
//         }
//     }

//     .description {
//         display: none;
//     }
// }

//date
// .cm-date {
//     .webform-calendar,
//     .form-item {
//         margin-right: 10px;
//         padding: 0;
//     }
// }
