.blockContact-container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 1200px;
    padding: 80px 0;

    @include xssm {
        padding: 24px;
    }

    .formule-choix-title {
        h3 {
            margin: 0;
        }
    }
}
  
.blockContact-imgContainer {
    margin: auto;
    margin-bottom: 24px;
    margin-top: 0;
    
    @include mdlg {
        height: auto;
        margin-bottom: 0;
        width: 30%;
    }

    @include xssm {
        //display: none;
        order: 1;
    }

    img {
        max-height: 240px;
    }
}

.blockContact-text {

    @include xssm {
        order: 2;
        padding: 0;
    }

    h2 {
        font-size: 2.25rem;
        font-weight: bold;

        @include xssm {
            font-size: 26px;
        }
    }
    .r-btn {
        margin: 20px 20px 20px 0;
    }

    .r-infos:last-child {
        padding: space(3) 0 0 0;
        text-align: left;
    }
}

.r-faq-chapo.r-infos {
    padding: 0 0 space(2) 0;
    display: inline-block;
    width: 100%;

    p {
        margin: 0;
        text-align: center;
    }
}

.blockContact-list {
    display: flex;
    margin: 0;
    padding: space(2) space(1.5) 0 space(1.5);
    width: 100%;

    @include mdlg {
        align-items: flex-start;
        padding: space(3) 0 0 0;
    }
}

.r-faq-list {
    .r-faq-list--ask {
        margin-bottom: space();
        &:last-child {
            margin-bottom: 0;
        }

        &.is-open {
            .r-faq-list--title {
                &:after {
                    transform: rotate(90deg);
                }
            }

            .r-faq-list--answer {
                display: inline-block;
                max-height: 100%;
                opacity: 1;
            }
        }

        .r-faq-list--title {
            align-items: center;
            color: color(primary);
            cursor: pointer;
            display: flex;
            font-family: $font-family-base;
            font-size: font-size(h4);
            font-weight: 700;
            //height: 48px;
            margin-bottom: space(0);
            margin-top: space(0);
            padding-bottom: space(0);
            padding-right: space(3);
            position: relative;
            text-align: left;

            &:after {
                content: $icon-arrow-right;
                font-family: main;
                position: absolute;
                right: 0;
                transition: all 0.25s ease 0s;
            }
        }

        .r-faq-list--answer {
            display: none;
            overflow: hidden;
            opacity: 0;
            transition: .3s linear;

            p {
                color: color(gray, darker);
                padding: space(.5) 0 0 0;
                margin: 0;
                text-align: left;
            }
        }
    }
}

.r-infos {
    margin: 0 0 20px;

    &:last-child {
        text-align: center;
    }

    p {
        color: color(gray, darker);
        margin: 4px 0;
    }
}

