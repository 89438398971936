.page_application {
    h1 {
        font-size: 1.8rem;
        margin: 24px 0 0 0;
        padding: 0 24px;
        text-align: left;
        @include smmdlg {
            font-size: 2.2rem;
            text-align: center;
        }
    }
}
.container_app {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 24px;
    @include smmdlg {
        flex-direction: row;
    }
    .txt_app {
        font-size: 1.2em;
        font-weight: bold;
    }
    .list_app {
        list-style: none;
        padding: 0;
        li {
            border-bottom: 1px solid color(gray, light);
            display: block;
            margin-bottom: 12px;
            padding-bottom: 12px;
        }
    }
    .title_list_app {
        align-items: center;
        display: flex;
        margin-bottom: 8px;
    }
    r-icon {
        color: color(primary);
        font-size: 2rem;
        margin-right: 12px;
    }
    h2 {
        font-size: 1.5rem;
        @include smmdlg {
            font-size: 1.7rem;
        }
    }
    .infos_list_app {
        font-weight: normal;
        margin: 0;
    }
    .dl_app {
        margin-bottom: 12px;
        @include smmdlg {
            margin-bottom: 0;
        }
        p {
            margin: 0;
        }
        .columns_container {
            margin-left: -12px;
            margin-right: -12px;
        }
        img {
            margin-top: 12px;
            padding-left: 12px;
            padding-right: 12px;
            width: 50%;

            @include smmdlg {
                max-width: 150px;
            }
        }
    }
}
