//
// Layout > Header
//

r-header {
    position: relative;
}

// .r-logo {
//     display: block;
//     text-align: center;
// }

// .r-logo--img {
//     max-height: $logo-height / 2;
//     max-width: calc(100% - 120px);

//     @include smmdlg {
//         height: $logo-height;
//         max-height: none;
//         max-width: none;
//         width: auto;
//     }
// }
