@font-face {
    font-family: 'main';
    font-weight: normal;
    font-style: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/icons/main.woff') format('woff'),
         url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/icons/main.woff2') format('woff2');
}

r-icon {
    display: inline-block;
    font-family: 'main';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;


    $icons: (
        arrow-bottom: '\EA01',
    arrow-down: '\EA02',
    arrow-left: '\EA03',
    arrow-right: '\EA04',
    checked: '\EA05',
    fleche_droite: '\EA06',
    fleche_gauche: '\EA07',
    lock: '\EA08',
    phone: '\EA09',
    ena-left: '\EA0A',
    ena-right: '\EA0B',
    angle-arrow-down: '\EA0C',
    angle-arrow-right: '\EA0D',
    envelope: '\EA0E',
    info: '\EA0F',
    facebook: '\EA10',
    home: '\EA11',
    user: '\EA12',
    error: '\EA13',
    notif-info: '\EA14',
    warning: '\EA15',
    cross: '\EA16',
    upload-button: '\EA17',
    newsletter: '\EA18',
    user-plain: '\EA19',
    check: '\EA1A',
    arrow-bottom-light: '\EA1B',
    handshake: '\EA1C',
    payment: '\EA1D',
    devices: '\EA1E',
    search: '\EA20',
    facebook-f: '\EA21',
    check-square: '\EA22',
    secure: '\EA23',
    gift: '\EA24',
    eye-open: '\EA25',
    eye-closed: '\EA26',
    email: '\EA27',
    briefcase: '\EA28',
    crown: '\EA29',
    offre-seule-b2b: '\EA2A',
    offre-groupe-b2b: '\EA2B',
    checking-square: '\EA2C',
    offre-famille-pprusse: '\EA2D',
    offre-list-pprusse: '\EA2E',
    star: '\EA2F',
    loupe: '\EA30',
    save-button: '\EA31',
    notification: '\EA32',
    contact-form: '\EA33',
    news: '\EA34',
    promotion: '\EA35',
    credit-impot: '\EA36',
    offre-groupe: '\EA37',
    offre-seule: '\EA38',
    attached: '\EA39',
    pc: '\EA3A',
    ajouter: '\EA3B',
    cellphone: '\EA3C',
    archiver: '\EA3D',
    magazine: '\EA3E',
    newspaper: '\EA3F',
    arrow-up-left: '\EA40',
    arrow-up-right: '\EA41',
    offre-seule-bonom: '\EA42',
    offre-groupe-bonom: '\EA43',
    offre-seul-pprusse: '\EA44',
    annonces-leg: '\EA45',
    facture: '\EA46',
    un-compte: '\EA47',
    trois-comptes: '\EA48',
    deconnexion: '\EA49',
    user-loggedin: '\EA4A',
    count-user: '\EA4B',
    user-si: '\EA4C',
    dots: '\EA4D',
    fleche: '\EA4E',
    lock-o: '\EA4F',
    mail: '\EA50',
    student: '\EA51',
    newsletters: '\EA52',
    read: '\EA53',
    society: '\EA54',
    teacher: '\EA55',
    responsive: '\EA56',
    question: '\EA57',
    user-plain-si: '\EA5A'
    );

    @each $name, $icon in $icons {
        &.r--#{$name}::before {
            content: $icon;
        }
    }
}

$icon-arrow-bottom: '\EA01';
$icon-arrow-down: '\EA02';
$icon-arrow-left: '\EA03';
$icon-arrow-right: '\EA04';
$icon-checked: '\EA05';
$icon-fleche_droite: '\EA06';
$icon-fleche_gauche: '\EA07';
$icon-lock: '\EA08';
$icon-phone: '\EA09';
$icon-ena-left: '\EA0A';
$icon-ena-right: '\EA0B';
$icon-angle-arrow-down: '\EA0C';
$icon-angle-arrow-right: '\EA0D';
$icon-envelope: '\EA0E';
$icon-info: '\EA0F';
$icon-facebook: '\EA10';
$icon-home: '\EA11';
$icon-user: '\EA12';
$icon-error: '\EA13';
$icon-notif-info: '\EA14';
$icon-warning: '\EA15';
$icon-cross: '\EA16';
$icon-upload-button: '\EA17';
$icon-newsletter: '\EA18';
$icon-user-plain: '\EA19';
$icon-check: '\EA1A';
$icon-arrow-bottom-light: '\EA1B';
$icon-handshake: '\EA1C';
$icon-payment: '\EA1D';
$icon-devices: '\EA1E';
$icon-search: '\EA20';
$icon-facebook-f: '\EA21';
$icon-check-square: '\EA22';
$icon-secure: '\EA23';
$icon-gift: '\EA24';
$icon-eye-open: '\EA25';
$icon-eye-closed: '\EA26';
$icon-email: '\EA27';
$icon-briefcase: '\EA28';
$icon-crown: '\EA29';
$icon-offre-seule-b2b: '\EA2A';
$icon-offre-groupe-b2b: '\EA2B';
$icon-checking-square: '\EA2C';
$icon-offre-famille-pprusse: '\EA2D';
$icon-offre-list-pprusse: '\EA2E';
$icon-star: '\EA2F';
$icon-loupe: '\EA30';
$icon-save-button: '\EA31';
$icon-notification: '\EA32';
$icon-contact-form: '\EA33';
$icon-news: '\EA34';
$icon-promotion: '\EA35';
$icon-credit-impot: '\EA36';
$icon-offre-groupe: '\EA37';
$icon-offre-seule: '\EA38';
$icon-attached: '\EA39';
$icon-pc: '\EA3A';
$icon-ajouter: '\EA3B';
$icon-cellphone: '\EA3C';
$icon-archiver: '\EA3D';
$icon-magazine: '\EA3E';
$icon-newspaper: '\EA3F';
$icon-arrow-up-left: '\EA40';
$icon-arrow-up-right: '\EA41';
$icon-offre-seule-bonom: '\EA42';
$icon-offre-groupe-bonom: '\EA43';
$icon-offre-seul-pprusse: '\EA44';
$icon-annonces-leg: '\EA45';
$icon-facture: '\EA46';
$icon-un-compte: '\EA47';
$icon-trois-comptes: '\EA48';
$icon-deconnexion: '\EA49';
$icon-user-loggedin: '\EA4A';
$icon-count-user: '\EA4B';
$icon-user-si: '\EA4C';
$icon-dots: '\EA4D';
$icon-fleche: '\EA4E';
$icon-lock-o: '\EA4F';
$icon-mail: '\EA50';
$icon-student: '\EA51';
$icon-newsletters: '\EA52';
$icon-read: '\EA53';
$icon-society: '\EA54';
$icon-teacher: '\EA55';
$icon-responsive: '\EA56';
$icon-question: '\EA57';
$icon-user-plain-si: '\EA5A';